import React from "react";
import Bubble from "../shared/bubble";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";

export default function Overview() {
  return (
    <CasesDetailsConsumer>
      {(context) => {
        return (
          <div className="content-container p-2">
            <div
              className="overview-tab p-2"
              data-cy="case-details-overview-content"
            >
              {context.state.overview.map((o: any, index: number) => {
                return (
                  <Bubble
                    direction={o.position}
                    type={o.type}
                    external={o.external}
                    sending={o.sending}
                    key={index}
                  >
                    {o.content}
                  </Bubble>
                );
              })}
            </div>
          </div>
        );
      }}
    </CasesDetailsConsumer>
  );
}
