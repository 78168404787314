import React, { FC } from "react";

type Props = {
  label: string|JSX.Element;
  name: string;
  onChange: (value: string) => void;
  rows?: number;
  value: string;  
};

const TextareaInput: FC<Props> = ({ label, name, onChange, rows, value}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <textarea
        rows={rows ? rows : 3}
        className="form-control"
        name={name}
        id={name}
        value={value}
        onChange={(event):void => onChange(event.currentTarget.value)}
      />
    </div>);
};
export default TextareaInput;