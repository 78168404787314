const isDuplicate = (
  data: {id: number, value: string}[],
  value: string,
  id: number|undefined,
): boolean => {
  return (
    data?.find((item) => {
      if (Number(item.id) === id) return false;      
      return item.value.toLowerCase() === value.toLowerCase();
    }) !== undefined
  );
};
export default isDuplicate;