import React, { FC } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  label: string;
  name: string;
  onChange: (value: Date|null) => void;
  value: Date|null;  
};

const DateInput: FC<Props> = ({ label, name, onChange, value}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <br />
      <DatePicker
        className="form-control"
        name={name}
        id={name}
        selected={value}
        onChange={(value):void =>{
          if (value instanceof Date) {
            onChange(value);
          } else {
            onChange(null);
          }
        }}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy HH:mm"
      />
    </div>);
};
export default DateInput;