import React from "react";
import { Sidenav } from "../../../shared/sidenav/sidenav";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";

export default function CaseDetailsSidenav() {
  // const {cases} = props;

  return (
    <CasesDetailsConsumer>
      {(context) => {
        return (
          <Sidenav>
            {context.state.case ? (
              <>
                <div
                  className="details-section"
                  data-cy="case-details-sidenav-overview"
                >
                  <div className="details-title">
                    Case #<span data-cy="case-details-title">{context.state.case.caseReference}</span>
                  </div>

                  <ul className="labeled-list">
                    <li>
                      <span className="list-title">Subject:</span>
                      <span className="list-item" data-cy="subject">
                        {context.state.case.summary}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Description:</span>
                      <span className="list-item" data-cy="description">
                        {context.state.case.description}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Team:</span>
                      <span className="list-item" data-cy="team">
                        {context.state.case.team}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Classification:</span>
                      <span className="list-item" data-cy="classification">
                        {context.state.case.classification}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Confidence:</span>
                      <span className="list-item" data-cy="confidence">
                        {context.state.case.aiProbability}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Status:</span>
                      <span className="list-item" data-cy="status">
                        {context.state.case.status}
                      </span>
                    </li>
                    {context.state.case.owner ? (
                      <li>
                        <span className="list-title">Owner:</span>
                        <span className="list-item" data-cy="owner">
                          {context.state.case.owner.firstName}{" "}
                          {context.state.case.owner.lastName}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                      <li>
                        <span className="list-title">Account #:</span>
                        <span className="list-item">{context.state.case.clientCaseId}</span>
                      </li>
                  </ul>
                </div>
                <div className="details-section">
                  <div className="details-title">
                    Client / Investor Information
                  </div>
                  <ul className="labeled-list">
                    <li>
                      <span className="list-title">Client:</span>
                      <span className="list-item" data-cy="client">
                        {context.state.case.client
                          ? context.state.case.client.name
                          : ""}
                      </span>
                    </li>
                    <li>
                      <span className="list-title">Email:</span>
                      <span className="list-item" data-cy="email">
                        {context.state.case.investorEmail}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
          </Sidenav>
        );
      }}
    </CasesDetailsConsumer>
  );
}
