import React, { FC } from "react";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { Menu, MenuItem } from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { FilterName, FiltersAction } from "../../../context/teamQ/reducers/filtersReducer";

export type selectOptionsType = { id: string; label: string };

export const ChooseFilters: FC = () => {
  const {
    state: { filters },
    dispatch: { filters: filtersDispatch },
  } = useTeamQContext();

  const unShownFilters = Object.keys(filters).filter((key: string) => !filters[key as FilterName].show);

  return (
    <div className="add-filter">
      {unShownFilters.length > 0 && (
        <PopupState variant="popover">
          {(popupState): JSX.Element => (
            <>
              <button className="btn btn-light btn-sm mb-3" {...bindTrigger(popupState)}>
                <i className="fas fa-plus"></i>
              </button>
              <Menu style={{ left: 40, top: 10 }} {...bindMenu(popupState)}>
                {unShownFilters.map((key: string, index: number) => {
                  const name = key as FilterName;
                  return (
                    <MenuItem
                      onClick={(): void => {
                        filtersDispatch({
                          type: FiltersAction.SHOW,
                          filterName: name,
                        });
                        popupState.close();
                      }}
                      key={index}
                    >
                      {filters[key as FilterName].label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </PopupState>
      )}
    </div>
  );
};
