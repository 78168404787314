export interface Classification {
  classificationId: number;
  name: string;
}

export interface ClassificationTeamAssignation {
  id: number;
  isAssigned: boolean;
  name: string;
  team: null | string;
}

export interface SettingsSaveDataClassification {
  id: number;
  label: string;
  isAssigned: boolean;
}

export const sortClassifications = (
  a: Classification,
  b: Classification
): number => {
  if (a.name === "Default") return 1;
  if (b.name === "Default") return -1;
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};
