import React, { FC, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { IncomingEmailAddressRule } from "../../../../models/incomingEmailAddressRule";
import { getIncomingEmailAddressRuleById } from "../../../../services/get.data.service";
import { putApplyIncomingEmailAddressRule } from "../../../../services/put.data.service";
import { SettingsParams } from "./types";

const SettingsApplyRule: FC = () => {
  const { id }: SettingsParams = useParams();

  const [applyNow, setApplyNow] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  
  const [rule, setRule] = useState<IncomingEmailAddressRule|null>(null);

  useEffect(()=>{
    getIncomingEmailAddressRuleById(Number(id)).then((response)=>{
      setRule(response.data);
    })
  },[]);

  useEffect(()=>{
    if (applyNow) {
      putApplyIncomingEmailAddressRule(Number(id)).then(()=>{
        setRedirect(true);
      });
    }
  },[applyNow]);

  if (redirect) return <Redirect to="/settings/systemaccounts/list" />;

  return rule && (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title">
            Apply Incoming Email Account Rule
          </div>
        </div>
        <div className="col-12">
          <div className="page-message">
            {rule.classification || rule.client || rule.autoClose ?
            <>This will apply the following actions to all unclaimed cases from <strong>{rule.emailAddress}</strong> with a subject of <strong>{rule.subject}</strong>.
              <ul>
                {rule.classification && <li>Change the classification to {rule.classification.name}</li>}
                {rule.client && <li>Change the client to {rule.client.clientName}</li>}
                {rule.autoClose && <li>Close the case</li>}
              </ul>
            </>
            :            
            <>There are no actions to apply for {rule.emailAddress}</>
          }
          </div>
        </div>
        <div className="col-12">
          <div className="page-message mt-3">
            {(rule.classification || rule.client || rule.autoClose) &&
              <button
                className="btn btn-primary"
                onClick={():void=>setApplyNow(true)}
              >
                Apply
              </button>
            }
            <button
              className="btn btn-link ml-3"
              onClick={():void=>setRedirect(true)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default SettingsApplyRule;
