import { AuthenticationContext, AdalConfig } from "react-adal";
import { logOut as postLogOut } from "./post.data.service";

const config: AdalConfig = {
  tenant: process.env.REACT_APP_AD_TENANT,
  clientId: process.env.REACT_APP_AD_CLIENTID || "s",
  redirectUri: process.env.REACT_APP_AD_REDIRECTURI,
  endpoints: {
    api: process.env.REACT_APP_AD_ENDPOINTAPI || "",
  }
};

export const authContext = new AuthenticationContext(config);

export const onlyLogout = (): void => {
  authContext.logOut();
};

export const getToken = (): string | null =>
  authContext.getCachedToken(config.clientId);

export const logOut = (): void => {
  postLogOut().then(() => {
    onlyLogout();
  });
};
