import React, { FC, useState } from "react";
import { CaseStatus, isMine } from "../helpers/caseStatus";
import { SendParams } from "../../../../models/send";
import { ACD } from "../../../../models/acd";
import { FullCase } from "../../../../models/case";
import { v4 as uuid } from "uuid";
import { AssignClientModal } from "../../../shared/actionModals/assignClientModal";
import { AssignUserModal } from "../../../shared/actionModals/assignUserModal";
import { ClassifyModal } from "../../../shared/actionModals/classifyModal";
import { SingleCloseModal } from "../../../shared/actionModals/singleCloseModal";
import { businessAreaSettings } from "../../../helpers/businessAreaSettings";

type Props = {
  acds: ACD[];
  caseData: FullCase;
  caseStatus: CaseStatus;
  send: (value: SendParams) => void;
};

const sendNote = (
  caseReference: string, 
  note: string, 
  send:(value: SendParams) => void
): void => {
  const payload = {
    note,
    requestId: uuid(),
    caseReference: caseReference,
  };
  send({ type: "AddNote", payload });
};

const Actions: FC<Props> = ({ acds, caseData, caseStatus, send }) => {
  const [showAssign, setShowAssign] = useState(false);

  const handleAssignSave = (userId: number, note: string): void => {
    setShowAssign(false);
    sendNote(caseData.caseReference, note, send);
    send({
      type: "AssignCases",
      payload: {
        userId: Number(userId),
        caseReferences: [caseData.caseReference],
      },
    });
  };

  const [showClose, setShowClose] = useState(false);

  const handleCloseSave = (clientId: number|null, note: string): void => {
    setShowClose(false);
    sendNote(caseData.caseReference, note, send);
    send({
      type: "CloseCase",
      payload: {
        clientId: clientId,
        caseReference: caseData.caseReference,
      },
    });
  };

  const [showACD, setShowACD] = useState(false);

  const handleACDSave = (acdId: number, note: string): void => {
    setShowACD(false);
    sendNote(caseData.caseReference, note, send);

    send({
      type: "SetCaseAcd",
      payload: {
        caseReference: caseData.caseReference,
        acdId: `${acdId}`,
      },
    });
  };

  const [showClassification, setShowClassification] = useState(false);

  const handleClassificationSave = (classificationId: number, note: string): void => {
    setShowClassification(false);
    sendNote(caseData.caseReference, note, send);

    send({
      type: "ReclassifyCases",
      payload: {
        caseReferences: [caseData.caseReference],
        classificationId: `${classificationId}`,
      },
    });
  };
  return (
    <>
        <div className="actions-container">
          {isMine(caseStatus) ? (
            <>
              <button
                className="btn btn-primary btn-sm btn-block mb-3"
                onClick={(): void => {
                  send({
                    type: "UnclaimCase",
                    payload: caseData.caseReference,
                  });
                }}
              >
                Unclaim
              </button>
              <button className="btn btn-primary btn-sm btn-block mb-3" onClick={(): void => setShowClose(true)}>
                Close
              </button>
            </>
          ) : (
            <button
              className="btn btn-primary btn-sm btn-block mb-3"
              onClick={(): void => {
                send({
                  type: "ClaimCase",
                  payload: caseData.caseReference,
                });
              }}
            >
              Claim
            </button>
          )}

          {isMine(caseStatus) ? (
            <>
              <div className="actions-title">Actions</div>
              <ul className="actions">
                <li>
                  <button onClick={(): void => setShowAssign(true)}>Assign Case</button>
                </li>
                {!businessAreaSettings().hideUpdateClient && (
                  <li>
                    <button onClick={(): void => setShowACD(true)}>Update Client</button>
                  </li>
                )}
                <li>
                  <button onClick={(): void => setShowClassification(true)}>Reclassify Case</button>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
        </div>

        {/* MODALS */}
        <SingleCloseModal
          saveMessage="Close Case"
          show={showClose}
          handleClose={(): void => setShowClose(false)}
          handleSave={handleCloseSave}
          clientId={caseData.client?.id || null}
          clientList={acds}
        />
        <AssignUserModal
          show={showAssign}
          handleClose={(): void => setShowAssign(false)}
          handleSave={handleAssignSave}
        />
        <AssignClientModal
          show={showACD}
          handleClose={(): void => setShowACD(false)}
          handleSave={handleACDSave}
          clientList={acds}
        />
        <ClassifyModal
          show={showClassification}
          handleClose={(): void => setShowClassification(false)}
          handleSave={handleClassificationSave}
          selectedCases={[caseData.caseReference]}
        />
    </>
  );
};
export default Actions;
