import React, { FC, useEffect, useState } from "react";
import { Classification, sortClassifications } from "../../../models/classification";
import { getClassificationsForCases } from "../../../services/get.data.service";
import CommonModal from "../components/commonModal";
import useModalState from "./hooks/useModalState";
import { ConfirmInputGroup } from "./shared/confirmInputGroup";
import ModalButtons from "./shared/modalButtons";

const getError = (id: string, message: string): string => {
  return !id || !message ? "Please select a classification and enter a note to reclassify case" : "";
};

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (classification: number, message: string) => void;
  selectedCases: string[];
}

export const ClassifyModal: FC<Props> = ({ show = false, handleClose, handleSave, selectedCases }) => {
  const { close, errorComponent, id, message, save, setId, setMessage } = useModalState(
    handleClose,
    handleSave,
    getError
  );

  const [classifications, setClassifications] = useState<Classification[]>([]);

  useEffect(()=>{
    if (show) getClassificationsForCases(selectedCases).then((res)=>setClassifications(res.data));
  }, [selectedCases, show]);

  return (
    <CommonModal
      show={show}
      title="Update Classification"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Reclassify" />}
    >
      {errorComponent}
      {classifications.length > 0 ? (
        <ConfirmInputGroup
          choices={classifications.sort(sortClassifications).map((classification: Classification) => {
            return {
              id: classification.classificationId,
              label: classification.name,
            };
          })}
          handleIdChange={setId}
          handleNoteChange={setMessage}
          id={id ? id : ""}
          note={message}
          title="Classification*"
          placeholder="Select Classification"
        />
      ) : (
        <>There are no Classifications found</>
      )}
    </CommonModal>
  );
};
