import { InputBase, withStyles } from "@material-ui/core";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  input: {
    display: "block",
    borderRadius: 4,
    width: "100%",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "0.5rem 1.5rem 0.5rem 0.75rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Source Sans Pro"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
