import React from "react";

import MessageInput from "./messageInput";
import MessageContent from "./messageContent";
import MessageList from "./messageList";

export default function Email() {
  return (
    <div className="content-container">
      <div className="email-container">
        <div className="email-messages">
          <MessageList></MessageList>
        </div>

        <div className="email-content">
          <MessageContent></MessageContent>
          <MessageInput></MessageInput>
        </div>
      </div>
    </div>
  );
}
