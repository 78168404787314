import React, { FC } from "react";
import { Switch } from "@material-ui/core";

type Props = {
  label: string;
  name: string;
  onChange: (value: boolean) => void;
  value: boolean;
  
};

const ToggleInput: FC<Props> = ({ label, name, onChange, value}) => {
  return (
    <div className="form-group">
    <label htmlFor={name}>{label}</label>
      <Switch
        name={name}
        id={name}
        checked={value}
        onChange={(event):void => onChange(event.currentTarget.checked)}
      />
    </div>
  );
};
export default ToggleInput;