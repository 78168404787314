import React, { FC } from "react";
import { Group } from "../../../../../models";
import {
  assignAccessAction,
  ClassificationAssignment,
  GroupClassificationAssignments,
  assignmentsAction,
  AssignedTeam,
} from "./assignmentsReducer";

type Props = {
  groupClassifications: Record<number, GroupClassificationAssignments>;
  groupClassificationDispatch: (action: assignAccessAction) => void;
  groupsSelected: Group[];
  teamName: string;
  teamId?: number;
};

const ClassificationPicker: FC<Props> = ({
  groupClassifications,
  groupClassificationDispatch,
  groupsSelected,
  teamName,
  teamId,
}) => {
  const sort = (a: ClassificationAssignment, b: ClassificationAssignment): number => {
    if (b.classification.name.toLowerCase() === "default") return 1;
    if (a.classification.name > b.classification.name) return 1;
    if (a.classification.name < b.classification.name) return -1;
    return 0;
  };

  const getButton = (groupId: number, assignment: ClassificationAssignment): JSX.Element => {
    if (assignment.classification.name.toLowerCase() === "default") return <></>;
    if (assignment.team && assignment.team.teamId === teamId) return <></>;

    return (
      <button
        className={`btn-sm ${assignment.team ? "btn-secondary" : "btn-primary"}`}
        onClick={(): void =>
          groupClassificationDispatch({
            type: assignmentsAction.ASSIGN_ACCESS,
            groupId,
            classificationId: assignment.classification.classificationId,
            team: {
              teamId: teamId,
              name: teamName,
            },
          })
        }
      >
        {assignment.team ? "Move" : "Assign"}
      </button>
    );
  };

  const getTeamName = (assignedTeam?: AssignedTeam): JSX.Element | string => {
    if (assignedTeam) {
      return assignedTeam.teamId === teamId ? <strong>{teamName}</strong> : assignedTeam.name;
    } else {
      return "Unassigned";
    }
  };

  return (
    <>
      {groupsSelected.map((group: Group) => {
        return (
          <div key={group.groupId}>
            <h3>
              {group.groupCode} - {group.businessName}
            </h3>
            <div>
              <div className="row p-1 border-bottom">
                <div className="col-5">Classification</div>
                <div className="col-5">Team</div>
                <div className="col-2">Action</div>
              </div>
              {Object.values(groupClassifications[group.groupId].assignments)
                .sort(sort)
                .map((assignment: ClassificationAssignment, index: number) => {
                  return (
                    <div key={index} className="row p-1">
                      <div className="col-5">{assignment.classification.name}</div>
                      <div className="col-5">{getTeamName(assignment.team)}</div>
                      <div className="col-2">{getButton(group.groupId, assignment)}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default ClassificationPicker;
