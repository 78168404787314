import React, { FC } from "react";

type Props = {
  label: string;
  name: string;
  onChange: (value: number) => void;
  value: number;  
};

const NumberInput: FC<Props> = ({ label, name, onChange, value}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type="number"
        className="form-control"
        name={name}
        id={name}
        value={value.toString()} // Forces number input to parse value removing leading 0
        onChange={(event):void => onChange(Number(event.currentTarget.value))}
      />
    </div>);
};
export default NumberInput;