import React, { FC } from "react";
import {
  ThemeProvider,
  createTheme,
  TableContainer,
  Table,
  makeStyles,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import SettingsTableBody from "./tableBody";
import SettingsTableHeader from "./tableHeader";

type Props = {
  rows: any;
  headers: any;
  paginator?: JSX.Element;
};

const SettingsTable: FC<Props> = ({
  rows,
  headers,
  paginator,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: "Source Sans Pro, sans-serif",
    },
  });

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <SettingsTableHeader headers={headers}></SettingsTableHeader>
          <SettingsTableBody rows={rows}></SettingsTableBody>
          {paginator && (
            <TableFooter>
              <TableRow id="footer">
                {paginator}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};
export default SettingsTable;
