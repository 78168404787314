import { FormControl, MenuItem } from "@material-ui/core";
import React, { FC, useState } from "react";
import { ACD } from "../../../models";
import CommonModal from "../components/commonModal";
import Dropdown from "../components/dropdown";
import ModalButtons from "./shared/modalButtons";

interface Props {
  show: boolean;
  clientId: number | null;
  clientList: ACD[];
  handleClose: () => void;
  handleSave: (clientId: number|null, message: string) => void;
  saveMessage: string;
}

export const SingleCloseModal: FC<Props> = ({ show, clientId, clientList, handleClose, handleSave, saveMessage }) => {
  const [id, setId] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const save = (): void => {
    const errorMessages: string[] = [];
    if (clientId === null && id === "") {
      errorMessages.push("Please enter the client to close this case");
    }
    if (!message) {
      errorMessages.push("Please enter a note to close this case");
    }
    if (errorMessages.length === 0) {
      handleSave(id === "" ? null : Number(id), message);
    } else {
      setErrors(errorMessages);
    }
  };
  const errorComponent = errors.length > 0 && (
    <ul>
      {errors.map((error: string, index: number) => (
        <li key={`error${index}`} className="alert alert-danger" role="alert" aria-label={error}>
          {error}
        </li>
      ))}
    </ul>
  );

  return (
    <CommonModal
      show={show}
      onHide={handleClose}
      title="Are you sure you want to close this case?"
      buttons={<ModalButtons close={handleClose} save={save} saveLabel={saveMessage} />}
    >
      {errorComponent}
      {!clientId && (
        <div className="form-group">
          <label htmlFor="choices">Client</label>
          <FormControl>
            <Dropdown name="choices" onChange={(id: string): void => setId(id)} value={`${id}`}>
              <MenuItem value="">Select Client</MenuItem>
              {clientList.map((client: ACD, index: number) => (
                <MenuItem value={client.clientId} key={index}>
                  {client.clientName}
                </MenuItem>
              ))}
            </Dropdown>
          </FormControl>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <textarea
          className="form-control"
          name="note"
          id="note"
          value={message}
          onChange={(event): void => setMessage(event.currentTarget.value as string)}
        ></textarea>
      </div>
    </CommonModal>
  );
};
