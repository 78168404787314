import axios, { AxiosError, AxiosResponse } from "axios";
import { Group, PutTeamAccess, TeamAccess, UnsavedGroup } from "../models";
import { ResponseData } from "../models/data";

import {
  apiUrl,
  axiosConfig,
  showError,
  handleError,
} from "./shared/dataServiceHelpers";

const putDataByUrl = (
  url: string,
  data?: unknown
): Promise<ResponseData<unknown>> => {
  return axios
    .put(`${apiUrl}${url}`, data, axiosConfig())
    .then((axiosRes: AxiosResponse) => {
      const response: ResponseData<unknown> = axiosRes?.data;
      if (!response.success)
        showError(response.message || "Unknown error while updating data");
      return response as ResponseData<unknown>;
    })
    .catch((error: AxiosError) => {
      handleError(error);
      return error.response?.data as ResponseData<unknown>;
    });
};

export const putApplyIncomingEmailAddressRule = (
  ruleId: number
): Promise<ResponseData<boolean>> => {
  return putDataByUrl("emailaccounts/putapplyincomingemailaddressrule/"+ ruleId) as Promise<
    ResponseData<boolean>
  >;
};

export const putGroup = (groupId: number, group: UnsavedGroup): Promise<ResponseData<Group>> => {
  return putDataByUrl(`groups/putgroup/${groupId}`, group) as Promise<ResponseData<Group>>;
}

export const putTeamAccesses = (
  teamAccesses: PutTeamAccess[]
): Promise<ResponseData<TeamAccess[]>> => {
  return putDataByUrl(`teamaccesses/putteamaccesses`, teamAccesses) as Promise<
    ResponseData<TeamAccess[]>
  >;
};
