import React, { useState } from "react";
import { useCaseContext } from "../../../../../context/casesDetailsContextProvider";

import { Checkbox } from "@material-ui/core";
import FileUploadModal from "../../../../shared/fileUpload/fileUploadModal";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { isMine } from "../../helpers/caseStatus";
import { SendEmailModal } from "../../../../shared/actionModals/sendEmailModal";
import isInternalMail from "../../../../shared/helpers/isInternalMail";


export default function MessageInput() {
  const [show, setShow] = useState(false);
  const [closeCase, setCloseCase] = useState(false);
  const context = useCaseContext();

  const handleSave = (): void => {
   
     if ( !show && !isInternalMail(context.state.draftEmail.toAddresses, context.state.draftEmail.ccAddresses)) 
     {
        setShow(true)
      } 

     else
     {  
        setShow(false);
        const sent = context.actions.sendEmail();

        if (sent && closeCase) 
        {
          context.actions.closeCase("");
          setCloseCase(false);
        }
      }     
      }
  
  return (
    <>
      {isMine(context.state.caseStatus) ? (
        <div className="email-input">
          <div className="content-container">
            <div className="flex-input">
              <label>To: </label>

              <Typeahead
                id="select-toAddresses"
                multiple
                allowNew
                placeholder="Select To"
                onChange={(e) =>
                  context.actions.draftEmailUpdate(
                    "toAddress",
                    e.map((email: any) => (typeof email === "string" ? email : email.label))
                  )
                }
                selected={context.state.draftEmail.toAddress}

                options={[]}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result: any, index: number) => (
                      <MenuItem option={result} position={index} key={index}>
                        {result.label}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>
            <div className="flex-input">
              <label>Cc: </label>
              <Typeahead
                id="select-ccAddresses"
                multiple
                allowNew
                placeholder="Select CC"
                onChange={(e) =>
                  context.actions.draftEmailUpdate(
                    "ccAddresses",
                    e.map((email: any) => (typeof email === "string" ? email : email.label))
                  )
                }
               selected={context.state.draftEmail.ccAddresses}
                options={[]}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result: any, index: number) => (
                      <MenuItem option={result} position={index} key={index}>
                        {result.label}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
            </div>
            <div className="flex-input">
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={context.state.draftEmail.subject}
                onChange={(e) => context.actions.draftEmailUpdate("subject", e.target.value)}
                className="input-white"
              />
            </div>
            {context.state.emailSelectedAttachments.length > 0 ? (
              <div className="flex-input">
                <label>Attachments: </label>
                <div className="attachments">
                  {context.state.emailSelectedAttachments.map((attachment: any, index: number) => (
                    <span key={index} className="attachment-pill">
                      {attachment.displayFileName ? attachment.displayFileName : attachment.fileName}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex-input body">
              <textarea
                aria-label="email body"
                name="body"
                value={context.state.draftEmail.body}
                onChange={(e) => context.actions.draftEmailUpdate("body", e.target.value)}
                className="input-white"
              ></textarea>
            </div>
          </div>
          <div className="email-actions">
            <div className="media">
              <button onClick={() => context.actions.toggleEmailAttachments(true)}>
                <i className="fas fa-paperclip"></i>
              </button>
            </div>
            <div className="save-button">
              <div className="history-check">
                Include history in reply?
                <Checkbox
                  checked={context.state.draftEmail.includeCaseHistory}
                  onClick={() =>
                    context.actions.draftEmailUpdate("includeCaseHistory", !context.state.draftEmail.includeCaseHistory)
                  }
                />
              </div>              
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(): void => {
                    setCloseCase(true);
                    handleSave();
                  }}
                >
                  Send Email
                </button>
              
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <SendEmailModal 
        show={show} 
        closeCase={closeCase} 
        handleClose={(): void => setShow(false)} 
        handleSave={handleSave}
      ></SendEmailModal>

      <FileUploadModal
        allFiles={context.state.attachments}
        handleClose={context.actions.toggleEmailAttachments}
        handleUpload={context.actions.handleAttachmentUpload}
        handleSubmit={context.actions.setEmailAttachments}
        show={context.state.isEmailAttachmentsOpen}
      ></FileUploadModal>
    </>
  );
}
