import React, { FC } from "react";
import CommonModal from "../components/commonModal";
import useModalState from "./hooks/useModalState";
import ModalButtons from "./shared/modalButtons";

interface Props {
  closeCase?: boolean;
  show: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

export const SendEmailModal: FC<Props> = ({ closeCase, show, handleClose, handleSave }) => {
  console.log("send email called");
  const { close, errorComponent, save } = useModalState(
    handleClose,
    () => handleSave()
  );

  return (
    <CommonModal
      show={show}
      
      
      title="Are you sure"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel={closeCase ? "Send and Close Case" : "Send"} />}
    >
      {errorComponent}
      <div>Are you sure you want to send this email outside of your organisation?</div>
    </CommonModal>
  );
};
