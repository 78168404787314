import { Reducer } from "react";

export type DisplaySettings = {
  pageName: PageName;
  pagination: PaginationSettings;
  sort: SortSettings;
};

export interface PaginationSettings {
  count: number;
  pageIndex: number;
  pageSize: number;
}

export enum PageName {
  GET_ALL_FOR_USER = "getallforuser",
  GET_MY_CASES = "getmycases",
  GET_UNCLAIMED = "getunclaimed",
}

export type SortSettings = {
  headerToSort: string;
  direction: SortDirection;
};

export type SortDirection = "asc" | "desc";

export enum displaySettingsAction {
  CHANGE_PAGE_NUMBER,
  CHANGE_ROWS_PER_PAGE,
  CHANGE_SORT,
  REPLACE_ALL,
  REPLACE_SORT,
  SET_COUNT,
  SET_PAGE,
}

export type changePageNumberAction = {
  type: displaySettingsAction.CHANGE_PAGE_NUMBER;
  pageIndex: string;
};

export type changeRowsPerPageAction = {
  type: displaySettingsAction.CHANGE_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type changeSortAction = {
  type: displaySettingsAction.CHANGE_SORT;
  headerToSort: string;
};

export type replaceAllAction = {
  type: displaySettingsAction.REPLACE_ALL;
  newSettings: DisplaySettings;
};

export type replaceSortAction = {
  type: displaySettingsAction.REPLACE_SORT;
  newSort: SortSettings;
};

export type setCountAction = {
  type: displaySettingsAction.SET_COUNT;
  count: number;
};

export type setPageAction = {
  type: displaySettingsAction.SET_PAGE;
  pageName: PageName;
};

export const displaySettingsInitialState: DisplaySettings = {
  pageName: PageName.GET_ALL_FOR_USER,
  pagination: {
    count: 0,
    pageIndex: 0,
    pageSize: 5,
  },
  sort: {
    headerToSort: "createddate",
    direction: "desc",
  },
};

const displaySettingsReducer: Reducer<
  DisplaySettings,
  | changePageNumberAction
  | changeRowsPerPageAction
  | changeSortAction
  | replaceAllAction
  | replaceSortAction
  | setCountAction
  | setPageAction
> = (state, action) => {
  switch (action.type) {
    case displaySettingsAction.CHANGE_PAGE_NUMBER: {
      const pagination: PaginationSettings = { 
        ...state.pagination, 
        ...{ 
          pageIndex: parseInt(action.pageIndex, 10)
        } 
      };
      return { ...state, ...{ pagination } };
    }

    case displaySettingsAction.CHANGE_ROWS_PER_PAGE: {
      const pagination: PaginationSettings = {
        ...state.pagination,
        ...{
          pageSize: action.rowsPerPage,
          pageIndex: 0,
        },
      };
      return { ...state, ...{ pagination } };
    }

    case displaySettingsAction.CHANGE_SORT: {
      const sort: SortSettings = {
        headerToSort: action.headerToSort,
        direction: "asc",
      };
      if (action.headerToSort === state.sort.headerToSort) {
        sort.direction = state.sort.direction === "asc" ? "desc" : "asc";
      }
      return { ...state, ...{ sort } };
    }

    case displaySettingsAction.REPLACE_ALL: {
      return action.newSettings;
    }

    case displaySettingsAction.REPLACE_SORT: {
      return { ...state,  ...{ sort: action.newSort } };
    }

    case displaySettingsAction.SET_COUNT: {
      const pagination: PaginationSettings = { ...state.pagination, ...{ count: action.count } };
      return { ...state, ...{ pagination } };
    }

    case displaySettingsAction.SET_PAGE: {
      const pagination: PaginationSettings = { 
        ...state.pagination, 
        ...{ 
          pageIndex: 0, 
        } 
      };
      return { 
        ...state, 
        ...{ pageName: action.pageName }, 
        ...{ pagination } 
      };
    }
  }
};

export default displaySettingsReducer;
