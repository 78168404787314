import moment from "moment";
import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PollingAddress } from "../../../../../models/pollingAddress";
import { getPollingAddresses } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import PollingAddressForm from "./pollingAddressForm";

const PollingAddressSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("EmailAccountHub"));
  const [data, setData] = useState<PollingAddress[] | null>([]);

  useLoadData(
    getPollingAddresses,
    (data) => {
      setData(data as PollingAddress[]);
    },
    hub,
    ["PollingEmailCreated", "PollingEmailUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((pollingAddress) => {
      return {
        id: pollingAddress.emailAccountId,
        data: [
          pollingAddress.emailAddress,
          pollingAddress.activelyPolling ? "True" : "False",
          moment(pollingAddress.lastPolled).format("DD/MM/YYYY HH:mm"),
          pollingAddress.client?.clientName ? pollingAddress.client?.clientName : "",
          pollingAddress.aiModel.name,
          pollingAddress.group.businessName,
          pollingAddress.productCode,
          <Link
            key={`list-${pollingAddress.emailAccountId}`}
            to={`/system-settings/emailaccounts/edit/${pollingAddress.emailAccountId}`}
          >
            Edit
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Email", "Actively Polling", "Last Polled", "Client", "Ai Model", "Group", "Product Code"]}
      rows={rows}
      titleSingle="Email Account"
      titlePlural="Email Accounts"
      type={SettingsType.emailaccounts}
    >
      {data !== null && (
        <PollingAddressForm
          isDuplicate={(value): boolean => {
            return isDuplicate(data.map((item)=>{
              return {id:item.emailAccountId, value: item.emailAddress}
            }), value, Number(id));
          }}
          existingPollingAddress={data?.find((pollingAddress) => pollingAddress.emailAccountId === Number(id))}
          hub={hub}
        />
      )}
    </SettingsView>
  );
};
export default PollingAddressSettings;
