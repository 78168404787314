/* eslint-disable max-lines */
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { PollingAddress } from "../../../../../models/pollingAddress";
import { getACDs, getAiModels, getDepartments, getGroups } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import { emailRegEx } from "../../../../shared/constants";
import DateInput from "../../shared/fields/dateInput";
import EmailInput from "../../shared/fields/emailInput";
import SelectInput, {SelectOption} from "../../shared/fields/selectInput";
import TextInput from "../../shared/fields/textInput";
import ToggleInput from "../../shared/fields/toggleInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";

type Props = {
  isDuplicate: (value: string)=> boolean;
  existingPollingAddress?: PollingAddress;
  hub: HubService;
};

const PollingAddressForm: FC<Props> = ({ isDuplicate, existingPollingAddress: existing, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [aiModelOptions, setAiModelOptions] = useState<SelectOption[]>([]);
  const [clientOptions, setClientOptions] = useState<SelectOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([]);
  const [activelyPolling, setActivelyPolling] = useState<boolean>(false);
  const [aiModelId, setAiModelId] = useState<number | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [clientId, setClientId] = useState<number | null>(null);
  const [departmentId, setDepartmentId] = useState<number | null>(null);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [productCode, setProductCode] = useState<string | null>(null);
  const [lastPolled, setLastPolled] = useState<Date>(new Date());
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existing) {
      setActivelyPolling(existing.activelyPolling);
      setAiModelId(existing.aiModel.aiModelId);
      setEmailAddress(existing.emailAddress);
      setClientId(existing.clientId);
      setDepartmentId(existing.departmentId);
      setGroupId(existing.group.groupId);
      setProductCode(existing.productCode);
      setLastPolled(
        // correct bad dates in the DB
        existing.lastPolled && existing.lastPolled > "2020-01-01T00:00:00"
          ? new Date(existing.lastPolled)
          : new Date()
      );
    }
    getACDs().then((clients) => {
      setClientOptions(
        clients.data.map((client) => {
          return {
            id: client.clientId,
            label: client.clientName,
          };
        })
      );
    });
    getAiModels().then((models) => {
      setAiModelOptions(
        models.data.map((model) => {
          return {
            id: model.aiModelId,
            label: model.name,
          };
        })
      );
    });
    getDepartments().then((departments) => {
      setDepartmentOptions(
        departments.data.map((dept) => {
          return {
            id: dept.departmentId,
            label: dept.departmentCode,
          };
        })
      );
    });
    getGroups().then((groups) => {
      setGroupOptions(
        groups.data.map((group) => {
          return {
            id: group.groupId,
            label: group.businessName,
          };
        })
      );
    });
  }, [existing]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (aiModelId === null) {
      errorMessages.push("Ai Model is required");
    }
    if (departmentId === null) {
      errorMessages.push("Unique Id is required");
    }
    if (emailAddress.trim().length === 0) {
      errorMessages.push("Email Address is required");
    } else if (!emailRegEx.test(emailAddress.trim())){
        errorMessages.push("Email is not a valid address");
    } else if (isDuplicate(emailAddress.trim())) {
        errorMessages.push("Email Address has already been used");      
    }
    if (groupId === null) {
      errorMessages.push("Group is required");
    }
    return errorMessages;
  };
  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existing) {
        sendSetting(hub, {
            type: "UpdatePollingAddress",
            payload: {
              emailAccountId: existing.emailAccountId,
              activelyPolling,
              aiModelId: aiModelId as number,
              emailAddress: emailAddress.trim(),
              clientId: clientId as number,
              departmentId: departmentId as number,
              groupId: groupId as number,
              productCode: !productCode ? null : productCode.trim(),
              lastPolled: lastPolled,
            }
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "AddPollingAddress",
            payload: {
              activelyPolling,
              aiModelId: aiModelId as number,
              emailAddress: emailAddress.trim(),
              clientId: clientId as number,
              departmentId: departmentId as number,
              groupId: groupId as number,
              productCode: !productCode ? null : productCode.trim(),
            }
          }, setRedirect);
      }
    }
  };

  if (redirect) return <Redirect to="/system-settings/emailaccounts/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existing ? "Edit Email Account" : "Create Email Account"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existing?.emailAddress}>
          <p>
            LastPolled: {existing?.lastPolled 
              ? moment(lastPolled).format("DD/MM/YYYY HH:mm") 
              : "none"}
          </p>
          <p>Actively polling: {existing?.activelyPolling ? "Yes" : "No"}</p>
        </SettingsFormInfo>
        
        <div className="col-sm-7">
          <div className="content-container p-3">
            <ToggleInput
              label="Actively Polling"
              name="actively-polling"
              onChange={setActivelyPolling}
              value={activelyPolling}
            />
            <DateInput
              label="Last Polled"
              name="last-polled"
              onChange={(value):void|null=>value && setLastPolled(value)}
              value={lastPolled}
            />
            <EmailInput
              label="Email Address"
              name="email-address"
              onChange={setEmailAddress}
              value={emailAddress}
            />
            <SelectInput
              label="Unique Id"
              name="department-id"
              onChange={setDepartmentId}
              id={departmentId}
              options={departmentOptions}
            />
            <SelectInput
              label="Client"
              name="client-id"
              onChange={setClientId}
              id={clientId}
              options={clientOptions}
            />
            <SelectInput
              label="Ai Model"
              name="ai-model-id"
              onChange={setAiModelId}
              id={aiModelId}
              options={aiModelOptions}
            />
            <SelectInput
              label="Group"
              name="group-id"
              onChange={setGroupId}
              id={groupId}
              options={groupOptions}
            />
            <TextInput
              label="Product Code"
              name="product-code"
              onChange={setProductCode}
              value={productCode ? productCode : ""}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PollingAddressForm;
