import React, { FC } from "react";

type Props = {
  errors?: string[];
  title: string;
};

const SettingsTitle: FC<Props> = ({ errors, title, children }) => {
  return (
    <>
    {errors && errors.length > 0 && (
      <div className="row pb-3">
        <ul className="col-12">
          {errors.map((error: string, index: number) => (
            <li key={`error${index}`} className="alert alert-danger" role="alert" aria-label={error}>
              {error}
            </li>
          ))}
        </ul>
      </div>
    )}
    <div className="row">
      <div className="col-8">
        <h2 className="page-title">{title}</h2>
      </div>
      <div className="col-4">
        <div className="settings-actions">{children}</div>
      </div>
    </div>
    </>
  );
};
export default SettingsTitle;
