import React, { FC } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";
import { useTeamQContext } from "../../../../../context/teamQContextProvider";
import { hideCheckboxes } from "../../../../helpers/hideCheckboxes";
import { useUserContext } from "../../../../../context/userContextProvider";

export type Header = {
  id: string; 
  label: string; 
  width: string|null;
}

type Props = {
  headers: Array<Header|undefined>; 
  sortHeader: (headerToSort: string)=> void;
  toggleAll: ()=> void;
  rowsLength: number;
  selectedLength: number;
}

const DataTableHeader: FC<Props> = ({ headers, sortHeader, toggleAll, rowsLength, selectedLength }) => {

  const { 
    state: {
      displaySettings: {
        sort: {
          direction, 
          headerToSort,
        }
      }
    }
  } = useTeamQContext();
  
  const {state:{user}} = useUserContext();

  const createSortHandler = (property: string) => (): void => {
    sortHeader(property.toString());
  };

  return (
    <TableHead>
      <TableRow>
        {(!hideCheckboxes() || user?.isManager) && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selectedLength > 0 && selectedLength < rowsLength}
              checked={rowsLength > 0 && selectedLength === rowsLength}
              onChange={toggleAll}
              inputProps={{ "aria-label": "select all" }}
            />
          </TableCell>
        )}

        {headers.map((header: Header|undefined, index: number) => {
          if (header !== undefined) {
            return (
              <TableCell key={index} style={{ width: header.width || "auto" }}>
                <TableSortLabel 
                  active={headerToSort === header.id}
                  direction={headerToSort === header.id ? direction : "asc"}
                  onClick={createSortHandler(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHeader;
