import React from "react";
import { Sidenav } from "../../shared/sidenav/sidenav";

import TeamQSidenavFilters from "./teamQSidenavFilters";

export default function TeamQSidenav() {
  return (
    <Sidenav>
      <TeamQSidenavFilters />
    </Sidenav>
  );
}
