import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FullCase, RecentCaseData, RecentCaseReference, ResponseData } from "../../../models";
import { getCasesById, getRecentCases } from "../../../services/get.data.service";

const CasesMenu: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [recentCases, setRecentCases] = useState<RecentCaseData[]>([]);

  const handleRecentCase = (recentCases: RecentCaseReference[]): void => {
    Promise.all(
      recentCases.slice(0, 3).map(async (recentCase: RecentCaseReference): Promise<RecentCaseData> => {
        return {
          recentCase,
          case: await getCasesById(recentCase.caseReference).then(
            (res: { data: unknown } | undefined): FullCase | undefined => {
              if (res && res.data) {
                return res.data as FullCase;
              } else return undefined; // There was an error fetching this case
            }
          ),
        };
      })
    ).then((res) => setRecentCases(res));
  };

  const loadRecentCases = (): void => {
    getRecentCases().then((response: ResponseData<RecentCaseReference[]>) => {
      handleRecentCase(response.data);
    });
  };

  const dropdownRef = useRef<HTMLLIElement>(null);
  useEffect(()=>{
    const handleClose = (event: MouseEvent) :void => {
      if (!dropdownRef.current || dropdownRef.current.contains(event.target as Node)) {
        return;
      }
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handleClose);
    return (): void => {
      document.removeEventListener("mousedown", handleClose);
    }
  }, [dropdownRef])

  useEffect(() => {
    if (isOpen) {
      loadRecentCases();
    }
  }, [isOpen]);

  return (
    <li className={isOpen ? "dropdown" : "dropdown closed"} ref={dropdownRef} >
      <div className="menu-link">
        <button className="nofocus" onClick={(): void => setIsOpen(!isOpen)}>
          <i className="fas fa-suitcase"></i>
        </button>
      </div>
      <div className="menu-dropdown">
        <div className="menu-dropdown-content">
          <div className="menu-body">
            {recentCases.map((recentCase: RecentCaseData, index: number) => {
              if (recentCase.case === undefined) {
                return (
                  <div className="case" key={index}>
                    <div className="case-content">
                      <div className="case-title">{`Error fetching case ${recentCase.recentCase.caseReference}`}</div>
                    </div>
                  </div>
                );
              } else {
                const recentCaseData: FullCase = recentCase.case;
                const date = moment(recentCaseData.createdDate).format("Do MMM YYYY mm:ss");
                return (
                  <div className="case" key={index}>
                    <div className="case-content">
                      <div className="case-title">{recentCaseData.summary}</div>
                      <div className="case-team-name">{recentCaseData.team}</div>
                      <div className="case-details">
                        Case #{recentCaseData.caseReference} - Created on {date}
                      </div>
                    </div>
                    <div className="case-buttons">
                      <Link className="btn btn-primary" to={`/case/${recentCaseData.caseReference}`} onClick={():void =>setIsOpen(false)}>
                        View
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="menu-footer">
            <Link to={"/teamq"} onClick={():void =>setIsOpen(false)}>View all cases</Link>
          </div>
        </div>
      </div>
    </li>
  );
};
export default CasesMenu;
