import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Classification } from "../../../../../models";
import { IncomingEmailAddressRule } from "../../../../../models/incomingEmailAddressRule";
import { getACDs, getAllClassifications } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import SelectInput, { SelectOption } from "../../shared/fields/selectInput";
import TextInput from "../../shared/fields/textInput";
import ToggleInput from "../../shared/fields/toggleInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";

type Props = {
  isDuplicate: (fieldToCheck: keyof IncomingEmailAddressRule, value: string)=> boolean;
  existingSystemAccount?: IncomingEmailAddressRule;
  hub: HubService;
};

const SystemAccountForm: FC<Props> = ({ isDuplicate, existingSystemAccount, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);

  const [classificationOptions, setClassificationOptions] = useState<SelectOption[]>([]);
  const [clientOptions, setClientOptions] = useState<SelectOption[]>([]);

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [suppressAcknowledgement, setSuppressAcknowledgement] = useState<boolean>(false);
  const [classificationId, setClassificationId] = useState<number|null>(null);
  const [autoClose, setAutoClose] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number|null>(null);

  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existingSystemAccount) {
      setEmailAddress(existingSystemAccount.emailAddress || "");
      setSubject(existingSystemAccount.subject || "")
      setClassificationId(existingSystemAccount.classificationId || null);
      setSuppressAcknowledgement(existingSystemAccount.suppressAutoResponse);
      setAutoClose(existingSystemAccount.autoClose);
      setClientId(existingSystemAccount.clientId || null);
    }
    getACDs().then((clients) => {
      setClientOptions(
        clients.data.map((client) => {
          return {
            id: client.clientId,
            label: client.clientName,
          };
        })
      );
    });
    getAllClassifications().then((response) =>{
      setClassificationOptions(response.data.map((classification: Classification): SelectOption =>{
        return {
          id: classification.classificationId,
          label: classification.name
        }
      }));
    })
  }, [existingSystemAccount]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (emailAddress.length === 0) {
      errorMessages.push("Email Address is required");
    } else {
      if (isDuplicate("emailAddress", emailAddress + subject.trim())) {
        errorMessages.push("Email Address / Subject combination has already been used");
      }
    }
    if (autoClose && clientId === null) {
      errorMessages.push("Client is required while Auto Close is selected");
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existingSystemAccount) {
        sendSetting(hub, {
            type: "UpsertIncomingEmailRule",
            payload: {
              incomingEmailAccountRuleId: existingSystemAccount.incomingEmailAccountRuleId,
              emailAddress,
              subject: subject.trim(),
              classificationId,
              clientId,
              suppressAutoResponse: suppressAcknowledgement,
              autoClose
            },
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "UpsertIncomingEmailRule",
            payload: {
              emailAddress,
              subject: subject.trim(),
              classificationId,
              clientId,
              suppressAutoResponse: suppressAcknowledgement,
              autoClose
            },
          }, setRedirect);
      }
    }
  };
  
  if (redirect) return <Redirect to="/settings/systemAccounts/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingSystemAccount ? "Edit SystemAccount" : "Create SystemAccount"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingSystemAccount?.emailAddress} >
        </SettingsFormInfo>
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput 
              label="Email Address" 
              name="email-account" 
              onChange={setEmailAddress} 
              value={emailAddress} 
            />
            <TextInput 
              label="Subject" 
              name="subject" 
              onChange={setSubject} 
              value={subject} 
            />
            <ToggleInput
              label="Suppress ACK"
              name="suppress-ack"
              onChange={setSuppressAcknowledgement}
              value={suppressAcknowledgement}
            />
            <SelectInput
              label="Classification"
              name="classification"
              onChange={setClassificationId}
              id={classificationId}
              options={classificationOptions}
            />
            <ToggleInput
              label="Auto Close"
              name="auto-close"
              onChange={setAutoClose}
              value={autoClose}
            />
            <SelectInput
              label="Client"
              name="client"
              onChange={setClientId}
              id={clientId||null}
              options={clientOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SystemAccountForm;
