import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { User } from "../../../../../models";
import { getUsers } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import UserForm from "./userForm";

const UserSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("UserHub"));
  const [data, setData] = useState<User[] | null>(null);

  useLoadData(
    getUsers,
    (data) => {
      setData(data as User[]);
    },
    hub,
    ["UserCreated", "UserUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((user) => {
      const teams = user.teams.map(
        (team, index: number) => `${team.teamName}${user.teams.length - 1 === index ? "" : ", "}`
      );
      return {
        id: user.id,
        data: [
          user.emailAddress,
          `${user.firstName} ${user.lastName}`,
          user.isManager ? "Yes" : "No",
          user.isSysAdmin ? "Yes" : "No",
          teams,
          <Link key={`edit-${user.id}`} to={`/settings/users/edit/${user.id}`}>
            Edit
          </Link>,
        ],
      };
    });

  return (
    <SettingsView
      headers={["Email", "Name", "Manager", "System Admin", "Teams", ""]}
      rows={rows}
      sortColumn={1}
      titleSingle="User"
      titlePlural="Users"
      type={SettingsType.users}
    >
      {data !== null && (
        <UserForm
          isDuplicate={(value): boolean => {
            return isDuplicate(data.map((item)=>{
              return {
                id: item.id,
                value: item.emailAddress
              };              
            }), value, Number(id));
          }}
          existingUser={data?.find((user) => user.id === Number(id))}
          hub={hub}
        />
      )}
    </SettingsView>
  );
};
export default UserSettings;
