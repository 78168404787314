export type BusinessAreaSettings = {
  hideBulkAssign: boolean;
  hideBulkClaim: boolean;
  hideBulkClassify: boolean;
  hideBulkClose: boolean; 
  hideAccountNumberColumn: boolean;
  hideUpdateClient: boolean;
  hideNewCase: boolean;
  openAttachmentsInNewTab: boolean;
  showSendAndClose: boolean;
  userGuideUrl: string;
};

export type BusinessAreaCode = "IN" | "TA" | "AB" | "default";

export const businessAreaSettingsAll: Record<BusinessAreaCode, BusinessAreaSettings> = {
  IN:  {
    hideBulkAssign: true,
    hideBulkClaim: true,
    hideBulkClassify: true,
    hideBulkClose: true, 
    hideAccountNumberColumn: false,
    hideNewCase: true,
    hideUpdateClient: false,
    openAttachmentsInNewTab: false,
    showSendAndClose: true,
    userGuideUrl: "https://portal.fnz.com/confluence/pages/viewpage.action?pageId=163685247"
  },
  TA: {
    hideBulkAssign: false,
    hideBulkClaim: false,
    hideBulkClassify: false,
    hideBulkClose: false, 
    hideAccountNumberColumn: true,
    hideNewCase: false,
    hideUpdateClient: false,
    openAttachmentsInNewTab: false,
    showSendAndClose: false,
    userGuideUrl: "https://portal.fnz.com/confluence/pages/viewpage.action?pageId=163685262"
  },
  AB:  {
    hideBulkAssign: false,
    hideBulkClaim: false,
    hideBulkClassify: false,
    hideBulkClose: false, 
    hideAccountNumberColumn: true,
    hideNewCase: false,
    hideUpdateClient: false,
    openAttachmentsInNewTab: true,
    showSendAndClose: false,
    userGuideUrl: "https://portal.fnz.com/confluence/pages/viewpage.action?pageId=163685265"
  },
  default:  {
    hideBulkAssign: false,
    hideBulkClaim: false,
    hideBulkClassify: false,
    hideBulkClose: false, 
    hideAccountNumberColumn: true,
    hideNewCase: false,
    hideUpdateClient: false,
    showSendAndClose: false,
    openAttachmentsInNewTab: false,
    userGuideUrl: ""
  },
};

