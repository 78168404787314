import React, { FC } from "react";
import { Modal } from "react-bootstrap";

type Props = {
  size?: "sm" | "lg" | "xl";
  show?: boolean;
  onHide: ()=> void;
  title: string | JSX.Element;
  buttons: JSX.Element;
};

export const CommonModal: FC<Props> = ({
  size = "lg",
  show = false,
  onHide,
  title,
  buttons,
  children,
}) => {
  return (
    <Modal size={size} show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title role="heading">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};
export default CommonModal;
