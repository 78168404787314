import { Team, TeamAccess } from "../../../../../models";
import { GetTeamAccesses } from "../../../../../services/get.data.service";
import { assignmentsAction, ClassificationAssignment, GroupClassificationAssignments, replaceAllAction } from "./assignmentsReducer";

  export const getData = async (
    groupClassificationDispatch: (action: replaceAllAction) => void,
    setGroupIdsSelected: (ids: number[]) => void,
    existingTeam?: Team,
  ):Promise<void> => {

    const {data: teamAccessList} = await GetTeamAccesses();

    const groupClassifications: Record<number,GroupClassificationAssignments> = {};

    const groupIds: number[] = [];

    teamAccessList.forEach((teamAccess: TeamAccess)=>{
      const newAssignment: ClassificationAssignment = {
        classification: {
          classificationId: teamAccess.classificationId,
          name: teamAccess.classificationName
        }
      };
      if (teamAccess.teamId) {
        newAssignment.team = {
          teamId: teamAccess.teamId,
          name: teamAccess.teamName || ""
        }
        if (teamAccess.teamId === existingTeam?.teamId && !groupIds.includes(teamAccess.groupId)) {
          groupIds.push(teamAccess.groupId);
        }
      }
      if (!groupClassifications[teamAccess.groupId]) {
        groupClassifications[teamAccess.groupId] = {
          groupId: teamAccess.groupId,
          assignments: {}
        }
      }
      
      groupClassifications[teamAccess.groupId].assignments[teamAccess.classificationId] = newAssignment;
    });
   
    groupClassificationDispatch({
      type: assignmentsAction.REPLACE_ALL,
      data: groupClassifications,
    });
    setGroupIdsSelected(groupIds);
  }