import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { getPollingAddresses } from "../../../services/get.data.service";
import moment from "moment";
import { ResponseData } from "../../../models";
import { PollingAddress } from "../../../models/pollingAddress";

const DashboardPolling = (): JSX.Element => {
  const [
    pollingData,
    setpollingData,
  ] = useState<ResponseData<PollingAddress[]> | null>();
  const colHeadings: string[] = ["Email", "Actively Polling", "Last Polled"];

  const setupPolling = async (): Promise<void> => {
    // Check if polling has happened every 60s
    setTimeout((): void => {
      getPollingAddresses().then((response) => setpollingData(response));
      setupPolling();
    }, 60000);
  };

  useEffect((): void => {
    getPollingAddresses().then((response) => setpollingData(response));
    setupPolling();
  }, []);

  return (
    <TableContainer classes={{ root: "polling-table" }}>
      <Table>
        <TableHead>
          <TableRow>
            {colHeadings.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {pollingData === undefined && (
            <TableRow>
              <TableCell classes={{ root: "test-loading-row" }}>
                Loading...
              </TableCell>
            </TableRow>
          )}
          {pollingData?.data.map((account, index) => {
            const pollTime = moment(account.lastPolled).calendar();
            const pollTimeFromNow = (
              <span className="poll-time-from-now">
                ({moment(account.lastPolled).startOf("hour").fromNow()})
              </span>
            );
            return (
              <TableRow key={index}>
                <TableCell>{account.emailAddress}</TableCell>
                <TableCell>{account.activelyPolling ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {pollTime} {pollTimeFromNow}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardPolling;
