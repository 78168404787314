import { AuthenticationContext } from "react-adal";
import { authContext } from "./services/auth.service";
import { runWithAdal } from 'react-adal';
import { runApp } from "./indexApp";

if (process.env.REACT_APP_IS_TEST === "CYPRESS" && location.hostname === "localhost") {
  // Allow Cypress to run the app within an iFrame
  const runWithAdalInCypress = (authContext: AuthenticationContext, app: any) => {
    
    if ((window.parent as any).Cypress) {
      (window.parent as any)._adalInstance = (window as any)._adalInstance;
    }
    authContext.handleWindowCallback();

    if (window === window.parent || (window as any).Cypress) {
      if (!authContext.isCallback(window.location.hash)) {
        if (!authContext.getCachedToken(authContext.config.clientId)) {
          authContext.login();
        } else {
          app();
        }
      }
    }
  };

  runWithAdalInCypress(authContext, runApp);

} else  {

  // In production or anywhere other than a Cypress test use Microsoft recommended code
  const DO_NOT_LOGIN = false;

  runWithAdal(
      authContext,
      runApp,
      DO_NOT_LOGIN
  );
}

