import React, { FC } from "react";
import { FormControl } from "@material-ui/core";
import { InputChangeEvent } from "./types";
import { Filter, FilterName } from "../../context/teamQ/reducers/filtersReducer";

export type selectOptionsType = { id: string; label: string };
interface Props {
  filterName: FilterName;
  filter: Filter;
  handleFilter: (event: InputChangeEvent) => void;
}

export const TextInput: FC<Props> = ({ filterName, filter, handleFilter }) => {
  if (!filter.show) return null;

  return (
    <div className="form-group">
      <label htmlFor={filterName}>{filter.label}</label>
      <FormControl>
        <input
          type="text"
          id={filterName}
          className="form-control"
          placeholder={filter.label}
          name={filterName}
          value={filter.value}
          onChange={(e): void => handleFilter(e as InputChangeEvent)}
        />
      </FormControl>
    </div>
  );
};
