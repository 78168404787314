import React, { useState } from "react";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { useUserContext } from "../../../context/userContextProvider";
import {
  getAlphabetisedAcdList,
  getAlphabetisedClassificationList,
  getAlphabetisedTeamList,
  getAlphabetisedUserList,
} from "../../shared/helpers/sorting";
import { SingleSelect } from "../../../components/Inputs/SingleSelect";
import { InputChangeEvent } from "../../../components/Inputs/types";
import { slaStatusOptions, statusOptions } from "./constants";
import { TextInput } from "../../../components/Inputs/TextInput";
import { ChooseFilters } from "./chooseFilters";
import { SavedFilters } from "./SavedFilters";
import { FilterActionButtons } from "./FilterActionButtons";
import { FilterName, FiltersAction } from "../../../context/teamQ/reducers/filtersReducer";

export default function TeamQSidenavFilters(): JSX.Element {
  const [checkBoxChecked, setCheckBoxChecked] = useState<number | null>(null);

  const {
    state: { acds, classifications, filters, teams },
    dispatch: { filters: filtersDispatch },
    actions: { load },
  } = useTeamQContext();
  const userContext = useUserContext();

  const clearForm = (): void => {
    setCheckBoxChecked(null);
    filtersDispatch({ type: FiltersAction.CLEAR_VALUES });
    load();
  };

  const handleFilter = (event: InputChangeEvent): void => {
    filtersDispatch({
      type: FiltersAction.SET_VALUE,
      filterName: event.target.name.toLowerCase() as FilterName,
      value: event.target.value as string,
    });
    setCheckBoxChecked(null);
  };

  return (
    <>
      <div className="title">
        Search Cases
        <button onClick={(): void => clearForm()} className="btn btn-danger btn-rounded float-right align-bottom">
          Clear
        </button>
      </div>
      <TextInput filterName={FilterName.caseReference} filter={filters.casereference} handleFilter={handleFilter} />
      <TextInput filterName={FilterName.description} filter={filters.description} handleFilter={handleFilter} />
      <SingleSelect
        filterName={FilterName.owner}
        filter={filters.owner}
        options={userContext.state.users ? getAlphabetisedUserList(userContext.state.users) : []}
        handleFilter={handleFilter}
      />
      <SingleSelect
        filterName={FilterName.team}
        filter={filters.team}
        options={teams ? getAlphabetisedTeamList(teams) : []}
        handleFilter={handleFilter}
      />
      <SingleSelect
        filterName={FilterName.classification}
        filter={filters.classification}
        options={classifications ? getAlphabetisedClassificationList(classifications) : []}
        handleFilter={handleFilter}
      />
      <TextInput filterName={FilterName.aiProbability} filter={filters.aiprobability} handleFilter={handleFilter} />
      <TextInput filterName={FilterName.subject} filter={filters.subject} handleFilter={handleFilter} />
      <TextInput filterName={FilterName.investorEmail} filter={filters.investoremail} handleFilter={handleFilter} />
      <SingleSelect
        filterName={FilterName.client}
        filter={filters.client}
        options={acds ? getAlphabetisedAcdList(acds) : []}
        handleFilter={handleFilter}
      />
      <SingleSelect
        filterName={FilterName.status}
        filter={filters.status}
        options={statusOptions}
        handleFilter={handleFilter}
      />
      <SingleSelect
        filterName={FilterName.slaStatus}
        filter={filters.slastatus}
        options={slaStatusOptions}
        handleFilter={handleFilter}
      />

      <ChooseFilters />
      <FilterActionButtons />
      <SavedFilters checkBoxChecked={checkBoxChecked} setCheckBoxChecked={setCheckBoxChecked} />
    </>
  );
}
