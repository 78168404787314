import React from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

export default function SettingsTableBody(props: any) {
  const { rows } = props;
  return (
    <TableBody>
      {rows.map((row: any, index: number) => {
        const data = Object.entries(row.data);

        return (
          <TableRow key={index}>
            {data.map((key: any, i: number) => {
              return <TableCell key={i}>{key[1]}</TableCell>;
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
