import { AxiosRequestConfig, AxiosError } from "axios";
import { getToken, onlyLogout } from "../auth.service";

export const apiUrl = `${process.env.REACT_APP_API_URL}/api/`;

export const axiosConfig = (): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
};

export const showError = (message: string): void => {
  // @TODO show error to user
  console.error(message);
};

export const handleError = (error: AxiosError): void => {
  if (error.response) {
    console.error("error", error.response);
    if (error.response.status === 401) {
      onlyLogout();
    } else {
      showError("Error connecting with the server.");
    }
  }
  showError(error.message || "Error connecting with the server.");
};
