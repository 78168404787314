import { FormControl, MenuItem } from "@material-ui/core";
import React, { FC } from "react";
import Dropdown from "../../../../shared/components/dropdown";

export type SelectOption = {
  id: number;
  label: string;
};

type Props = {
  label: string;
  name: string;
  onChange: (id: number|null) => void;
  id: number|null;  
  options: SelectOption[];
};

const SelectInput: FC<Props> = ({ label, name, onChange, id, options}) => {

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <FormControl>
        <Dropdown 
          name={name}
          value={id !== null ? id.toString() :  ""}
          onChange={(id):void => 
            id === ""
              ? onChange(null)
              : onChange(Number(id))           
          }
        >
          <MenuItem value="">{`Select ${label}`}</MenuItem>
          {options.map((option: SelectOption, i: number) => {
            return (
              <MenuItem value={option.id.toString()} key={i}>
                {option.label}
              </MenuItem>
            );
          })}
        </Dropdown>
      </FormControl>
    </div>
  );
};
export default SelectInput;