import React from "react";
import { DashboardContext } from "../../../context/dashboard/dashboard";
import { DashboardConsumer } from "../../../context/dashboardContextProvider";
import DashboardAllocationChart from "./charts/allocationChart";

const DashboardAllocation = () => {
  return (
    <>
      <DashboardConsumer>
        {(context: DashboardContext) => {
          const users = context.state.casesByUser.map(
            (userCases) => userCases.userName
          );
          const cases = context.state.casesByUser.map(
            (userCases) => userCases.totalCases
          );

          return (
            <>
              <div className="row mb-5">
                <div className="col-12">
                  <div className="content-container chart-padding">
                    <h2 className="chart-heading">{`Cases Allocation `}</h2>
                    <DashboardAllocationChart users={users} cases={cases} />
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </DashboardConsumer>
    </>
  );
};

export default DashboardAllocation;
