import React, { FC, useState } from "react";
import { Redirect } from "react-router-dom";import { SendParams } from "../../../../models";
import HubService from "../../../../services/hub.service";
import sendSetting from "./helpers/sendSetting";

type Props = {
  deleteMessage: SendParams;
  displayId: string;
  hub: HubService
  title: string;
};

const SettingsDelete: FC<Props> = ({ deleteMessage, displayId, hub, title }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  
  if (redirect) return <Redirect to="/settings/systemAccounts/list" />;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title">
            Delete {title}
          </div>
        </div>
      <div className="col-12">
        <div className="page-message">
          Are you sure you want to delete this item: {displayId}
        </div>
      </div>
        <div className="col-12">
          <div className="page-message mt-3">
            <button
              className="btn btn-danger"
              onClick={():void => {
                sendSetting(hub, deleteMessage, setRedirect);
              }}
            >
              Delete
            </button>
            <button
              className="btn btn-link ml-3"
              onClick={()=>setRedirect(true)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default SettingsDelete;
