import React, { FC } from "react";
import { Sidenav } from "../../shared/sidenav/sidenav";
import { useRouteMatch, NavLink } from "react-router-dom";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import GroupIcon from "@material-ui/icons/Group";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

interface SettingsSidenavLink {
  text: string;
  path: string;
  icon: JSX.Element;
}

const SettingsSidenav: FC = () => {
  const { path } = useRouteMatch();
  const links: SettingsSidenavLink[] = [
    {
      text: "Classifications",
      path: `${path}/classifications`,
      icon: <GroupWorkIcon />,
    },
    {
      text: "Clients",
      path: `${path}/acds`,
      icon: <AccountBalanceIcon />,
    },
    {
      text: "System Accounts",
      path: `${path}/systemaccounts`,
      icon: <SettingsApplicationsIcon />,
    },
    {
      text: "Teams",
      path: `${path}/teams`,
      icon: <ContactMailIcon />,
    },
    {
      text: "Users",
      path: path,
      icon: <GroupIcon />,
    },
  ];

  return (
    <Sidenav>
      <ul className="sidenav-menu">
        {links.map((link, index) => {
          return (
            <li key={index}>
              <NavLink exact activeClassName="active" to={link.path}>
                <i className="material-icons">{link.icon}</i>
                {link.text}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Sidenav>
  );
}
export default SettingsSidenav;