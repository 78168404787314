import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ResultsTableRowData, SettingsType } from "../pages/types";
import SettingsTitle from "./settingsTitle";
import SettingsTable from "./table/table";

type Props = {
  headers: string[]
  rows: ResultsTableRowData[] | null;
  sortColumn?: number;
  titleSingle: string;
  titlePlural: string;
  type: SettingsType;
};

const SettingsList: FC<Props> = ({ headers, rows, sortColumn = 0, titleSingle, titlePlural, type }) => {
  rows?.sort((a: ResultsTableRowData, b: ResultsTableRowData): number => {
    return a.data[sortColumn].localeCompare(b.data[sortColumn]);
  });
  return (
    <>
      <SettingsTitle title={`${titlePlural} List`}>
        <div className="settings-actions">
          <Link to={`${type}/create`} className="btn btn-primary mb-3 float-right">
            {`Add New ${titleSingle}`}
          </Link>
        </div>
      </SettingsTitle>
      <SettingsTable headers={headers} rows={rows} />
    </>
  );
};
export default SettingsList;
