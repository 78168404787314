import React, { FC } from "react";
import {
  TableRow,
  ThemeProvider,
  TableContainer,
  Table,
  TableFooter,
  TablePagination,
  makeStyles,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import DataTableHeader, { Header } from "./table-header";
import DataTableBody from "./table-body";
import TablePaginationActions from "./pagination";
import { selectedCasesAction } from "../../../../../context/teamQ/reducers/selectedCasesReducer";
import { useTeamQContext } from "../../../../../context/teamQContextProvider";
import { displaySettingsAction } from "../../../../../context/teamQ/reducers/displaySettingsReducer";
import { RowMappingOutputData } from "../../cases/mapCaseDataToRowData";

type Props = {
  headers: Array<Header|undefined>;
  rows: RowMappingOutputData[];
}

const DataTable:FC<Props> = (props: Props) => {
  const { headers, rows } = props;

  const {
    dispatch: { 
      displaySettings: displaySettingsDispatch, 
      selectedCases: selectedCasesDispatch 
    },
    state: {
      displaySettings: { pagination },
      selectedCases,
    },
  } = useTeamQContext();

  const theme = createTheme({
    typography: {
      fontFamily: "Source Sans Pro, sans-serif",
    },
  });

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    displaySettingsDispatch({
      type: displaySettingsAction.CHANGE_ROWS_PER_PAGE,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <DataTableHeader
            headers={headers}
            sortHeader={(headerToSort: string): void =>
              displaySettingsDispatch({ type: displaySettingsAction.CHANGE_SORT, headerToSort })
            }
            toggleAll={():void =>{
              selectedCasesDispatch({
              type: selectedCasesAction.TOGGLE_ALL,
              caseReferences: rows.map((row: RowMappingOutputData) => {
                return row.caseRef;
              })})
            }}
            rowsLength={rows.length}
            selectedLength={selectedCases.length}
          ></DataTableHeader>

          <DataTableBody
            rows={rows}
          ></DataTableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                component="td"
                rowsPerPageOptions={[5, 10, 25, 100]}
                colSpan={headers.length + 1}
                count={pagination.count}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={(event, page): void =>
                  displaySettingsDispatch({
                    type: displaySettingsAction.CHANGE_PAGE_NUMBER,
                    pageIndex: `${page}`,
                  })
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default DataTable;
