import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Classification } from "../../../../../models/classification";
import { getAllClassifications } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import ClassificationForm from "./classificationForm";

const ClassificationSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("ClassificationHub"));
  const [data, setData] = useState<Classification[] | null>([]);

  useLoadData(
    getAllClassifications,
    (data) => {
      setData(data as Classification[]);
    },
    hub,
    ["ClassificationCreated", "ClassificationUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((classification) => {
      return {
        id: classification.classificationId,
        data: [
          classification.name,
          <Link key={`lis-${classification.classificationId}`} to={`/settings/classifications/edit/${classification.classificationId}`}>
            Edit
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Name", "Classifier Path", ""]}
      rows={rows}
      titleSingle="Classification"
      titlePlural="Classifications"
      type={SettingsType.classifications}
    >
    {data !== null && (
      <ClassificationForm 
        isDuplicate={(fieldToCheck, value): boolean=>{
          return isDuplicate(data.map((item)=>{
            return {
              id: item.classificationId,
              value: item.name,
            };
          }), value, Number(id));
        }}
       existingClassification={data?.find((classification) => classification.classificationId === Number(id))} 
       hub={hub}
      />
    )}
    </SettingsView>
  );
};
export default ClassificationSettings;
