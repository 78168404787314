export enum CaseStatus {
  unClaimed = "UNCLAIMED",
  claimed = "CLAIMED",
  claimedToMe = "CLAIMEDTOME",
  claimedToMeNotValid = "CLAIMEDTOMENOTVALID",
  closed = "CASECLOSED",
  loading = "CASELOADING",
}

export function isNotClaimed(state: CaseStatus): boolean {
  return CaseStatus.unClaimed === state;
}

export function isClaimed(state: CaseStatus): boolean {
  return (
    CaseStatus.claimed === state ||
    CaseStatus.claimedToMe === state ||
    CaseStatus.claimedToMeNotValid === state
  );
}

export function isMine(state: CaseStatus): boolean {
  if (CaseStatus.closed === state) return false;

  return (
    CaseStatus.claimedToMe === state || CaseStatus.claimedToMeNotValid === state
  );
}

export function isNotValid(state: CaseStatus): boolean {
  return CaseStatus.claimedToMeNotValid === state;
}

export function isClosed(state: CaseStatus): boolean {
  return CaseStatus.closed === state;
}

export function isLoading(state: CaseStatus): boolean {
  return CaseStatus.loading === state;
}
