export interface DashboardACD {
  id: number;
  label: string;
}

export interface DashboardUser {
  id: number;
  label: string;
}

export interface DashboardCaseByUser {
  userId: number;
  userName: string;
  totalCases: number;
}

export interface DashboardState {
  acds: DashboardACD[];
  casesByUser: DashboardCaseByUser[];
  dashboardData?: DashboardData[];
  filters: any;
  isLoading: boolean;
  selectedACDs: DashboardACD[];
  selectedUsers: DashboardUser[];
  users: DashboardUser[];
}

export interface DashboardData {
  acd: string;
  details: DashboardDataDetails[];
  totalCases: number;
}

export interface DashboardDataDetails {
  slaType: slaType;
  total: number;
  stats: DashboardDataDetailsStats[];
}

export interface DashboardDataDetailsStats {
  cases: number;
  heading: string;
}

export enum slaType {
  within = "WithinSla",
  amber = "AmberSla",
  breach = "BreachedSla",
}

export interface DashboardContextActions {
  changeACDs: (acds: DashboardACD[]) => void;
  changeUsers: (users: DashboardUser[]) => void;
  setFilters: (name: string, value: string) => void;
  clearData: () => void;
}

export interface DashboardContext {
  state: DashboardState;
  actions: DashboardContextActions;
}
