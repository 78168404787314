import { FormControl, MenuItem, Switch } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { ACD } from "../../../models/acd";
import { Classification } from "../../../models/classification";
import { EmailRulesData } from "../../../models/send";
import { getClassificationsForCases } from "../../../services/get.data.service";
import CommonModal from "../components/commonModal";
import Dropdown from "../components/dropdown";
import ModalButtons from "./shared/modalButtons";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (data: EmailRulesData) => void;
  initialData: {
    acds: ACD[];
    caseReference: string;
    email: string;
  };
}

export const EmailRulesModal: FC<Props> = ({ show, handleClose, handleSave, initialData }) => {
  const [classifications, setClassifications] = useState<Classification[]>([]);
  const [classification, setClassification] = useState<string>("Select Classification");
  const [client, setClient] = useState<string>("Select Client");
  const [suppressAutoResponse, setSuppressAutoResponse] = useState<boolean>(false);
  const [autoClose, setAutoClose] = useState<boolean>(false);

  const [errors, setErrors] = useState<string[]>([]);

  useEffect(()=>{
    if (show) getClassificationsForCases([initialData.caseReference]).then((res)=>setClassifications(res.data));
  }, [show]);
  
  const clear = (): void => {
    setClassification("Select Classification");
    setClient("Select Client");
    setSuppressAutoResponse(false);
    setAutoClose(false);
  };

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (autoClose && client === "Select Client") {
      errorMessages.push("Client is required while Auto Close is selected");
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      const data: EmailRulesData = {
        emailAddress: initialData.email,
        classificationId: classification !== "Select Classification" ? Number(classification) : null,
        clientId: client !== "Select Client" ? Number(client) : null,
        suppressAutoResponse: suppressAutoResponse,
        autoClose: autoClose,
      };

      clear();
      handleSave(data);
    }
  };

  const close = (): void => {
    clear();
    handleClose();
  };

  return (
    <CommonModal
      show={show}
      title="Add email to email rules"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Save" />}
    >
      {errors.length > 0 && errors.map((error:string, key: number):JSX.Element =>{
        return <div className="alert alert-danger" role="alert" key={key}>
          {error}
        </div>
      })}

      <div className="form-group">
        <label htmlFor="email">Email address</label>
        <input type="email" value={initialData.email} className="form-control" id="email" disabled />
      </div>

      <div className="form-group">
        <label htmlFor="suppressack">Suppress ACK</label>
        <Switch
          id="suppressack"
          checked={suppressAutoResponse}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setSuppressAutoResponse(e.target.checked)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="classifications">Classifications</label>

        <FormControl>
          <Dropdown
            name="classifications"
            onChange={(id: string): void => setClassification(id)}
            value={classification}
          >
            <MenuItem value="Select Classification">Select Classification</MenuItem>
            {classifications.map((option: Classification, i: number) => {
              return (
                <MenuItem value={option.classificationId.toString()} key={i}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Dropdown>
        </FormControl>
      </div>

      <div className="form-group">
        <label htmlFor="autoclose">Auto Close</label>
        <Switch
          id="autoclose"
          checked={autoClose}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setAutoClose(e.target.checked)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="clients">Client</label>

        <FormControl>
          <Dropdown name="clients" onChange={(id: string): void => setClient(id)} value={client}>
            <MenuItem value="Select Client">Select Client</MenuItem>
            {initialData.acds.map((option: ACD, i: number) => {
              return (
                <MenuItem value={option.clientId} key={i}>
                  {option.clientName}
                </MenuItem>
              );
            })}
          </Dropdown>
        </FormControl>
      </div>
    </CommonModal>
  );
};
