import React, { FC } from "react";

type Props = {
  containerClass?: string
}

const Loading: FC<Props> = ({containerClass = "dashboard-loading content"}) => {
  return (
    <div className={containerClass}>
      <div className="loading">
        <div className="loading-bar"></div>
      </div>
    </div>
  );
}
export default Loading;