import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnection,
} from "@microsoft/signalr";
import { SendParams } from "../models";
import { getToken } from "./auth.service";
import { showError } from "./shared/dataServiceHelpers";

export default class HubService {
  private hub: HubConnection;
  private hubURL = process.env.REACT_APP_EVENT_HANDLER + "/";
  private hubName = "";

  constructor(hub: string) {
    this.hubName = hub;
    this.hub = new HubConnectionBuilder()
      .configureLogging(LogLevel.Critical)
      .withUrl(`${this.hubURL}${hub}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return `${getToken()}`;
        },
      })
      .withAutomaticReconnect()
      .build();
    this.hub.onreconnected(()=>{
      this.start();
    });
  }

  start(): Promise<void> {
    return this.hub
      .start()
      .catch((error) => {
        showError("Connection to server lost. Please refresh");
        console.error(`Error connecting to ${this.hubName}:`, error)
      });
  }

  stop(): Promise<void> {
    return this.hub
      .stop()
      .catch((error) => {
        console.error(`Error disconnecting from ${this.hubName}:`, error)
      });

  }

  listen(event: string, callback: (...args: any[])=>void): void {
    if (this.hub) {
      this.hub.on(event, callback);
    }
  }

  send(event: string|SendParams, data?: unknown): Promise<void> {
    const handleError = (error: unknown):Promise<void> => {
      showError("Data connecion failed please refresh page and try again");
      console.error(`Error sending ${event} to ${this.hubName}:`, error as string);
      return Promise.reject();
    }
    if (typeof event !== "string") {
      const {type, payload} = event;
      event = type;
      data = payload;
    }
    if (this.hub) {
      if (data) {
        return this.hub
          .invoke(event, data)
          .catch(handleError);
      } else {
        return this.hub
          .invoke(event)
          .catch(handleError);
      }
    } else {
      return Promise.reject("No hub to send to");
    }
  }
}
