import React from "react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import DashboardSLACharts from "./dashboardSLACharts";
import DashboardPolling from "./dashboardPolling";
import DashboardAllocation from "./dashboardAllocation";

const DashboardLayout = (): JSX.Element => {
  const { path, url } = useRouteMatch();

  return (
    <div className="content">
      <div className="row">
        <div className="col-lg-12">
          <ul className="tabs dashboard-tabs">
            <li>
              <NavLink exact activeClassName="active" to={`${url}/overview`}>
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" to={`${url}/polling`}>
                Polling Status
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row fixed-height">
        <div className="col-lg-12">
          <Switch>
            <Route exact path={`${path}/overview`}>
              <DashboardSLACharts />
              <DashboardAllocation />
            </Route>
            <Route path={`${path}/polling`}>
              <DashboardPolling />
            </Route>
            <Route exact path={`${path}`}>
              <Redirect to={`${url}/overview`} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
