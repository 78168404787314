import React, { useState } from "react";
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";

import "react-dropzone-uploader/dist/styles.css";

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => {
  return (
    <div>
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {previews}
      {files.length > 0 && submitButton}
    </div>
  );
};

export default function FileUpload(props: any) {
  const [note, setNote] = useState("");

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    props.handleUpload(
      files.map((f) => f.file),
      note
    );
    setNote("");
    allFiles.forEach((f) => f.remove());
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="upload-note">Note</label>
        <textarea
          id="upload-note"
          name="subject"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className="form-control"
        ></textarea>
      </div>
      <Dropzone
        LayoutComponent={Layout}
        onSubmit={handleSubmit}
        submitButtonContent="Upload files"
        inputContent="Drop files here or click to select file"
      />
    </>
  );
}
