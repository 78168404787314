import { Reducer } from "react";
import { Classification } from "../../../../../models";

export type AssignedTeam = {
  teamId?: number;
  name: string;
};

export type ClassificationAssignment ={
  classification: Classification;
  team?: AssignedTeam;
}

export type GroupClassificationAssignments = {
  groupId: number;
  assignments: Record<number,ClassificationAssignment>;
}

export enum assignmentsAction {
  REPLACE_ALL,
  ASSIGN_ACCESS,
  UNASSIGN_GROUPS,
}

export type assignAccessAction = {
  type: assignmentsAction.ASSIGN_ACCESS;
  groupId: number;
  classificationId: number;
  team?: AssignedTeam;
};

export type replaceAllAction = {
  type: assignmentsAction.REPLACE_ALL;
  data: Record<number,GroupClassificationAssignments>;
};

export type unassignGroupsAction = {
  type: assignmentsAction.UNASSIGN_GROUPS;
  data: number[];
};

const assignmentsReducer: Reducer<
Record<number,GroupClassificationAssignments>,
  | assignAccessAction
  | replaceAllAction
  | unassignGroupsAction
> = (state, action) => {
  switch (action.type) {
    case assignmentsAction.ASSIGN_ACCESS: {
      const newState = {...state};
      newState[action.groupId].assignments[action.classificationId].team = action.team;
      return newState;
    }

    case assignmentsAction.REPLACE_ALL: {
      return action.data;
    }

    case assignmentsAction.UNASSIGN_GROUPS: {
      const newState = {...state};
      action.data.forEach((groupId: number)=>{        
        Object.keys(newState[groupId].assignments).map((classificationString: string)=>{
          const classificationId = Number(classificationString);
          newState[groupId].assignments[classificationId].team = undefined;
        });
      });
      return newState;
    }
  }
};

export default assignmentsReducer;
