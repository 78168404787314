import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Team } from "../../../../../models/team";
import { getTeams } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import { MANAGEMENT_TEAM, SYSTEM, SYSTEM_ADMIN_TEAM } from "./constants";
import TeamForm from "./teamForm";

const TeamSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("TeamHub"));
  const [data, setData] = useState<Team[] | null>(null);

  useLoadData(
    getTeams,
    (data) => {
      setData(data as Team[]);
    },
    hub,
    ["TeamCreated", "TeamUpdated"]
  );

  const showEditLink = (teamName: string): boolean => {
    if (teamName.toLowerCase() === MANAGEMENT_TEAM || teamName.toLowerCase() === SYSTEM_ADMIN_TEAM || teamName.toLowerCase() === SYSTEM) {
      return false;
    }
    if (teamName.substring(0, 10) === "Default - ") {
      return false;
    }
    return true;
  }

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((team) => {
      return {
        id: team.teamId,
        data: [
          team.name,
          showEditLink(team.name) ? (
            <Link key={`edit-${team.teamId}`} to={`/settings/teams/edit/${team.teamId}`}>
              Edit
            </Link>
          ) : (
            <></>
          ),
        ],
      };
    });
  return (
    <SettingsView
      headers={["Team Name", ""]}
      rows={rows}
      titleSingle="Team"
      titlePlural="Teams"
      type={SettingsType.teams}
    >
      {data !== null && (
        <TeamForm
          isDuplicate={(fieldToCheck, value): boolean => {
            return isDuplicate(data.map((item)=>{
              return {
                id: item.teamId,
                value: item.name
              }
            }), value, Number(id));
          }}
          existingTeam={data?.find((team) => team.teamId === Number(id))}
        />
      )}
    </SettingsView>
  );
};
export default TeamSettings;
