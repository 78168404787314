import React from "react";
import { Modal } from "react-bootstrap";
import FileUpload from "./fileUpload";

export default function FileUploadDocumentsModal(props: any) {
  const close = () => {
    props.handleClose(false);
  };

  return (
    <Modal show={props.show} animation={false} size={"xl"} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content-container pt-3">
          <FileUpload handleUpload={props.handleUpload} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
