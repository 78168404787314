import { SortSettings } from "../reducers/displaySettingsReducer";
import { Filter, Filters } from "../reducers/filtersReducer";

export const getQueryString = (filters: Filters, sort: SortSettings):string =>{
  
  const queryStringArray: string[] = [];
  let filterQueryString = "";
  Object.entries(filters).forEach((entry: [string, Filter])=>{
    const [key, filter] = entry;
    if (filter.show && filter.value !== "") {
      filterQueryString += `${key}|${encodeURIComponent(filter.value)},`
    }
  });

  if (filterQueryString  !== "") {
    queryStringArray.push(`filterBy=${filterQueryString}`);
  }
  queryStringArray.push(`orderBy=${sort.headerToSort}|${sort.direction}`);
  
  return queryStringArray.join("&");
};