import React, { useEffect } from "react";
import { Chart, ArcElement, DoughnutController } from "chart.js";
import { DashboardData, DashboardDataDetails } from "../../../../context/dashboard/dashboard";

Chart.register(ArcElement, DoughnutController);

interface Props {
  data: DashboardData;
  position: number;
}

interface Stats {
  green: number;
  amber: number;
  red: number;
}

const SlaSummaryChart = ({ data, position }: Props) => {
  const filteredStats: DashboardDataDetails[] = data.details;
  const stats: Stats = {
    green: 0,
    amber: 0,
    red: 0,
  };

  filteredStats.forEach((slaTypeSummary: DashboardDataDetails) => {
    switch (slaTypeSummary.slaType) {
      case "WithinSla":
        stats.green = slaTypeSummary.total;
        break;
      case "AmberSla":
        stats.amber = slaTypeSummary.total;
        break;
      case "BreachedSla":
        stats.red = slaTypeSummary.total;
        break;
      default:
        console.error("Incorrect SLA Type detected");
    }
  });

  useEffect(() => {
    const slaSummaryChart = new Chart(`sla-doughnut-${position}`, {
      type: "doughnut",
      data: {
        labels: ["Within SLA", "Near SLA", "Breached SLA"],
        datasets: [
          {
            backgroundColor: ["#25A45D", "#ffc107", "#EF626C"],
            data: [stats.green, stats.amber, stats.red],
          },
        ],
      },
      options: {
        cutout: 60,
        rotation: 45,
        responsive: true,
      },
    });

    return () => {
      if (slaSummaryChart) {
        slaSummaryChart.destroy();
      }
    };
  }, []);

  return (
    <div className="chart-flex">
      <div className="chart-section">
        <div className="chart-relative">
          <canvas
            id={`sla-doughnut-${position}`}
            width="10"
            height="10"
          ></canvas>
          <div className="chart-title">
            <div className="c-title">Total</div>
            <div className="total">{data.totalCases}</div>
            <i className="fas fa-suitcase"></i>
          </div>
        </div>
      </div>
      <div className="legend-section">
        <div className="legend-item">
          <div className="legend-box legend-box-success"></div>
          <div className="legend-label">{stats.green}</div>
        </div>
        <div className="legend-item">
          <div className="legend-box legend-box-warning"></div>
          <div className="legend-label">{stats.amber}</div>
        </div>
        <div className="legend-item">
          <div className="legend-box legend-box-danger"></div>
          <div className="legend-label">{stats.red}</div>
        </div>
      </div>
    </div>
  );
};

export default SlaSummaryChart;
