import React, { FC } from "react";
import CommonModal from "../components/commonModal";
import useModalState from "./hooks/useModalState";
import ModalButtons from "./shared/modalButtons";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (filterName: string) => void;
}
const getError = (id: string, message: string): string => {
  return !message ? "Please add a name for the filter" : "";
};
export const SaveFilterModal: FC<Props> = ({ show = false, handleClose, handleSave }) => {
  const { close, errorComponent, message, save, setMessage } = useModalState(
    handleClose,
    (id: number, message: string) => handleSave(message),
    getError
  );

  return (
    <CommonModal
      show={show}
      title="Save Filter"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Save Filter" />}
    >
      {errorComponent}
      <div className="form-group">
        <label htmlFor="filtername">Filter Name</label>
        <input
          type="text"
          className="form-control"
          name="filtername"
          id="filtername"
          value={message}
          onChange={(event): void => setMessage(event.currentTarget.value as string)}
        />
      </div>
    </CommonModal>
  );
};
