import React from "react";
import { TableCell, TableRow, TableHead } from "@material-ui/core";

export default function SettingsTableHeader(props: any) {
  const { headers } = props;
  return (
    <TableHead>
      <TableRow>
        {headers.map((header: string, index: number) => {
          return <TableCell key={index}>{header}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
}
