import React, { FC } from "react";
import { FormControl, MenuItem } from "@material-ui/core";
import { Classification } from "../../../models/classification";
import Dropdown from "../../shared/components/dropdown";
import { PollingAddress } from "../../../models/pollingAddress";
import { NewCaseAttachment } from "../../../models/newCase";
import { Menu as ThMenu, MenuItem as ThMenuItem, Typeahead } from "react-bootstrap-typeahead";

export type TypeAheadValues ={
  customOption: boolean; 
  label: string;
}

type Props = {
  options: {
    classifications: Classification[];
    pollingAddresses: PollingAddress[];
  },
  state: {
    pollingAddressId: string;
    classificationId: string;
    toAddresses: TypeAheadValues[];
    ccAddresses: TypeAheadValues[];
    subject: string;
    message: string;
    attachments: NewCaseAttachment[];
  },
  setState: {
    setPollingAddressId: (pollingAddressId: string) => void;
    setClassificationId: (classification: string) => void;
    setToAddresses: (toAddresses: TypeAheadValues[]) => void;
    setCcAddresses: (ccAddresses: TypeAheadValues[]) => void;
    setSubject: (subject: string) => void;
    setMessage: (message: string) => void;
    openEmailAttachments: ()=>void;
  }
};

export const NewCaseForm: FC<Props> = ({
  options: {
    classifications,
    pollingAddresses,
  }, 
  state: {
    classificationId,
    pollingAddressId,
    toAddresses,
    ccAddresses,
    subject,
    message,
    attachments,
  },
  setState: {
    setPollingAddressId,
    setClassificationId,
    setToAddresses,
    setCcAddresses,
    setSubject,
    setMessage,
    openEmailAttachments,
  }
  
}) => {

  return (
    <>
      <div className="form-group">
        <FormControl>
          <label htmlFor="new-case-polling-address">From</label>
          <Dropdown
            name="new-case-polling-addresst"
            onChange={(id: string): void => setPollingAddressId(id)}
            value={pollingAddressId}
          >
            {pollingAddresses.length !== 1 && <MenuItem value="">Select From Address</MenuItem>}
            {pollingAddresses.map((pollingAddress: PollingAddress, index: number) => (
              <MenuItem value={pollingAddress.emailAccountId} key={index}>
                {pollingAddress.emailAddress}
              </MenuItem>
            ))}
          </Dropdown>
        </FormControl>
      </div>
      <div className="form-group">
        <FormControl>
          <label htmlFor="new-case-classification">Classification</label>
          <Dropdown
            name="new-case-classification"
            onChange={(classificationId: string): void => setClassificationId(classificationId)}
            value={classificationId}
          >
            {classifications.length !== 1 && <MenuItem value="">Select Classification</MenuItem>}
            {classifications.map((classification: Classification, index: number) => (
              <MenuItem value={classification.classificationId} key={index}>
                {classification.name}
              </MenuItem>
            ))}
          </Dropdown>
        </FormControl>
      </div>
      <div className="email-input">
          <div className="content-container">
            <div className="flex-input to">
              <label style={{width: "4em", display: "inline-block"}}>To: </label>
              <Typeahead
                id="select-toAddresses"
                multiple
                allowNew
                placeholder="Select To"
                onChange={(values: TypeAheadValues[]):void =>setToAddresses(values)}
                selected={toAddresses}
                options={[]}
                renderMenu={(results , menuProps):JSX.Element => (
                  <ThMenu {...menuProps}>
                    {results.map((result: unknown, index: number) => {
                      const castResult = result as TypeAheadValues;
                      return (
                        <ThMenuItem option={castResult} position={index} key={index}>
                          {castResult.label}
                        </ThMenuItem>
                      );
                    })} 
                  </ThMenu>
                )}
              />
            </div>

            <div className="flex-input cc">
              <label style={{width: "4em", display: "inline-block"}}>Cc: </label>
              <Typeahead
                id="select-ccAddresses"
                multiple
                allowNew
                placeholder="Select CC"
                onChange={(values: TypeAheadValues[]):void => setCcAddresses(values)}
                selected={ccAddresses}
                options={[]}
                renderMenu={(results , menuProps):JSX.Element => (
                  <ThMenu {...menuProps}>
                    {results.map((result: unknown, index: number) => {
                      const castResult = result as TypeAheadValues;
                      return (
                        <ThMenuItem option={castResult} position={index} key={index}>
                          {castResult.label}
                        </ThMenuItem>
                      );
                    })} 
                  </ThMenu>
                )}
              />
            </div>
            <div className="flex-input">
              <label style={{width: "4em", display: "inline-block"}}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e): void => setSubject(e.target.value)}
                className="input-white"
              />
            </div>
            <div className="flex-input"  style={{margin: "3px 0"}}>
              <label>
                <button onClick={(): void => {
                    openEmailAttachments();
                  }}>
                  <i className="fas fa-paperclip"></i>
                </button>
              </label>
              <div className="attachments">
                {attachments.map((attachment: NewCaseAttachment, index: number) => (
                  <span key={index} className="attachment-pill">
                    {attachment.fileName}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex-input body">
              <textarea
                aria-label="email body"
                name="body"
                value={message}
                onChange={(e): void => setMessage(e.target.value)}
                className="input-white"
              ></textarea>
            </div>
          </div>
        </div>
    </>
  );
};
export default NewCaseForm;
