import React, { useContext, FC, ReactNode } from "react";
import { PortalContext } from "../../../context/portalContext";

type Props = {
  children?: ReactNode;
};

export const Sidenav: FC<Props> = ({ children }: Props) => {
  const { isSidenavOpen } = useContext(PortalContext);
  return (
    <div className={isSidenavOpen ? "sidenav open" : "sidenav"}>
      <div className="sidenav-content">{children}</div>
    </div>
  );
};
