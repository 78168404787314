import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Group } from "../../../../../models/group";
import { getGroups } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import GroupForm from "./groupForm";

const GroupSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("GroupHub"));
  const [data, setData] = useState<Group[] | null>([]);

  useLoadData(
    getGroups,
    (data) => {
      setData(data as Group[]);
    },
    hub,
    ["GroupCreated", "GroupUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((group) => {
      return {
        id: group.groupId,
        data: [
          group.groupCode,
          group.businessName,
          group.sendToStudio ? "Yes" : "No",
          group.studioUrl,
          group.physicalMail ? "Yes" : "No",
          group.suppressAcknowledgement ? "Yes" : "No",
          group.numberOfDaysToKeepClosedCases,
          <Link key={`lis-${group.groupId}`} to={`/system-settings/groups/edit/${group.groupId}`}>
            Edit
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Group Code", "Business Name", "Send to Studio", "Studio Url", "Physical Mail", "Suppress ACK", "Days to Keep Closed Cases", ""]}
      rows={rows}
      titleSingle="Group"
      titlePlural="Groups"
      type={SettingsType.groups}
    >
    {data !== null && (
      <GroupForm 
        isDuplicate={(fieldToCheck, value): boolean=>{
          return isDuplicate(data.map((item)=>{
            return {
              id: item.groupId,
              value: fieldToCheck === "groupCode" ? item.groupCode : item.businessName,
            };
          }), value, Number(id));
        }}
       existingGroup={data?.find((group) => group.groupId === Number(id))} 
       hub={hub}
      />
    )}
    </SettingsView>
  );
};
export default GroupSettings;
