import { useEffect } from "react";
import HubService from "../../../services/hub.service";
import { casesInFlightAction, removeOneAction, replaceAction } from "../reducers/casesInFlightReducer";

const useHubListeners = async (
  casesHubConnection: HubService,
  singleCaseHubConnection: HubService,
  casesInFlightDispatch: (action: removeOneAction | replaceAction) => void,
  setDataLoad: (state: boolean) => void,
): Promise<void> => {
  useEffect(() => {
    casesHubConnection.start().then(() => {
      casesHubConnection.listen("CaseCreated", (landedCase: string) => {
        casesInFlightDispatch({
          type: casesInFlightAction.REMOVE_ONE,
          caseReference: landedCase,
        });
        setDataLoad(true);
      });

      casesHubConnection.listen("CaseClaimed", (landedCase: string) => {
        casesInFlightDispatch({
          type: casesInFlightAction.REMOVE_ONE,
          caseReference: landedCase,
        });
        setDataLoad(true);
      });

      casesHubConnection.listen("CaseUpdated", (landedCase: string) => {
        casesInFlightDispatch({
          type: casesInFlightAction.REMOVE_ONE,
          caseReference: landedCase,
        });
        setDataLoad(true);
      });

      casesHubConnection.listen("InFlightCases", (casesInFlight: string[]) => {
        casesInFlightDispatch({
          type: casesInFlightAction.REPLACE,
          caseReferences: casesInFlight,
        });
      });

      singleCaseHubConnection.start();
    });
  }, []);
};
export default useHubListeners;

