import React, { FC } from "react";
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";

import "react-dropzone-uploader/dist/styles.css";

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps): JSX.Element => {
  return (
    <div>
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {previews}
      {files.length > 0 && submitButton}
    </div>
  );
};

interface Props {
  handleUpload: (files: File[])=>void;
  initialFiles: File[];
}

export const AttachmentUpload: FC<Props> = ({ handleUpload, initialFiles }) => {

  const handleSubmit: IDropzoneProps["onSubmit"] = (files) => {
    handleUpload(
      files.map((f) => f.file)
    );
  };

  return (
    <>
      <Dropzone
        LayoutComponent={Layout}
        onSubmit={handleSubmit}
        submitButtonContent="Upload files"
        inputContent="Drop files here or click to select file"
        initialFiles={initialFiles}
      />
    </>
  );
}
export default AttachmentUpload