import React from "react";
import { DashboardContext } from "../../../context/dashboard/dashboard";
import {
  DashboardConsumer,
  DashboardContextProvider,
} from "../../../context/dashboardContextProvider";
import Loading from "../../shared/loading";
import DashboardLayout from "./dashboardLayout";
import DashboardSidenav from "./dashboardSidenav";

const Dashboard = (): JSX.Element => {
  return (
    <DashboardContextProvider>
      <div className="dashboard page-layout">
        <DashboardConsumer>
          {(context: DashboardContext) => {
            return !context.state.isLoading ? (
              <>
                <DashboardSidenav />
                <DashboardLayout />
              </>
            ) : (
              <Loading></Loading>
            );
          }}
        </DashboardConsumer>
      </div>
    </DashboardContextProvider>
  );
};

export default Dashboard;
