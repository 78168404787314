import React, { useState } from "react";

type ModalState = {
  close: () => void;
  errorComponent: JSX.Element;
  id: string;
  message: string;
  save: () => void;
  setId: (id: string) => void;
  setMessage: (message: string) => void;
};

const useModalState = (
  handleClose: () => void,
  handleSave: (id: number, message: string) => void,
  getError?: (id: string, message: string) => string
): ModalState => {
  const [message, setMessage] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const clear = (): void => {
    setMessage("");
    setId("");
    setError("");
  };

  const save = (): void => {
    
    const errorMessage = getError ? getError(id, message) : "";
    if (errorMessage === "") {
      clear();
      handleSave(Number(id), message);
    } else {
      setError(errorMessage);
    }
  };

  const close = (): void => {
    clear();
    handleClose();
  };

  const errorComponent =
    error.length > 0 ? (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    ) : <></>;

  return { close, errorComponent, id, message, save, setId, setMessage };
};
export default useModalState;
