import React, { FC } from "react";
import { ACD } from "../../../models";
import { ConfirmInputGroup } from "./shared/confirmInputGroup";
import CommonModal from "../components/commonModal";
import ModalButtons from "./shared/modalButtons";
import useModalState from "./hooks/useModalState";

const getError = (id: string, message: string): string => {
  return !id || !message ? "Please select a client and enter a note to close cases" : "";
};

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (client: number, message: string) => void;
  clientList: ACD[];
}

export const BulkCloseModal: FC<Props> = ({ show = false, handleClose, handleSave, clientList }) => {
  const { close, errorComponent, id, message, save, setId, setMessage } = useModalState(
    handleClose,
    handleSave,
    getError
  );

  return (
    <CommonModal
      show={show}
      title="Close Cases"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Close Cases" />}
    >
      {errorComponent}
      {clientList.length > 0 ? (
        <ConfirmInputGroup
          choices={clientList.map((client: ACD) => {
            return {
              id: client.clientId,
              label: client.clientName,
            };
          })}
          handleIdChange={setId}
          handleNoteChange={setMessage}
          id={id ? id : ""}
          note={message}
          title="Set Client"
          placeholder="Select Client"
        />
      ) : (
        <>No clients found</>
      )}
    </CommonModal>
  );
};
