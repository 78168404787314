import { v4 as uuid } from "uuid";
import { SendParams } from "../../../../models/send";

export const handleBulkAssignSave = (
  send: (sendParams: SendParams) => void,
  selectedCases: string[],
  userId: number,
  note: string
): void => {
  selectedCases.forEach((caseReference: string) => {
    const payload = {
      note,
      requestId: uuid(),
      caseReference,
    };
    send({ type: "AddNote", payload });
  });

  send({
    type: "AssignCases",
    payload: {
      userId: Number(userId),
      caseReferences: selectedCases,
    },
  });
};

export const handleBulkReclassifySave = (
  send: (sendParams: SendParams) => void,
  selectedCases: string[],
  classificationId: number,
  note: string
): void => {
  selectedCases.forEach((caseReference: string) => {
    const payload = {
      note,
      requestId: uuid(),
      caseReference,
    };
    send({ type: "AddNote", payload });
  });

  send({
    type: "ReclassifyCases",
    payload: {
      caseReferences: selectedCases as string[],
      classificationId: `${classificationId}`,
    },
  });
};

export const handleBulkCloseSave = (
  send: (sendParams: SendParams) => void,
  clientId: number,
  selectedCases: string[],
  note: string
): void => {
  selectedCases.forEach((caseReference: string) => {
    const payload = {
      note,
      requestId: uuid(),
      caseReference,
    };
    send({ type: "AddNote", payload });
  });

  send({
    type: "CloseCases",
    payload: {
      clientId,
      caseReferences: selectedCases,
    },
  });
};

export const claimSelectedCases = (send: (sendParams: SendParams) => void, selectedCases: string[]): void => {
  if (selectedCases) {
    send({ type: "ClaimCases", payload: selectedCases });
  }
};
