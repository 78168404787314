import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import FileUpload from "./fileUpload";
import FileSelect from "./fileSelect";

export default function FileUploadModal(props: any) {
  const [tab, setTab] = useState("files");

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const toggleFile = (file: any) => {
    if (selectedFiles.includes(file)) {
      setSelectedFiles(selectedFiles.filter((sf) => sf !== file));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const tabsBody = () => {
    switch (tab) {
      case "files":
        return (
          <FileSelect
            selectedFiles={selectedFiles}
            toggleFile={toggleFile}
          ></FileSelect>
        );
      case "upload":
        return <FileUpload handleUpload={props.handleUpload}></FileUpload>;
      default:
        break;
    }
  };

  const close = () => {
    props.handleClose(false);
  };

  return (
    <Modal show={props.show} animation={false} size={"xl"} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="tabs">
          <li>
            <button
              className={tab === "files" ? "active" : ""}
              onClick={() => setTab("files")}
            >
              Files
            </button>
          </li>
          <li>
            <button
              className={tab === "upload" ? "active" : ""}
              onClick={() => setTab("upload")}
            >
              Upload
            </button>
          </li>
        </ul>
        <div className="content-container pt-3">{tabsBody()}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={close}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() => props.handleSubmit(selectedFiles)}
          disabled={selectedFiles.length === 0}
        >
          Upload{" "}
          {selectedFiles.length > 0
            ? `(${selectedFiles.length} ${
                selectedFiles.length === 1 ? "file" : "files"
              })`
            : ""}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
