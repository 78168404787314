import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import { Navbar } from "./shared/navbar/navbar";
import Dashboard from "./pages/dashboard/dashboard";
import TeamQ from "./pages/teamq/teamQ";
import Details from "./pages/details/details";
import Settings from "./pages/settings/settings";
import SystemSettings from "./pages/settings/systemSettings";
import { PortalContextProvider } from "../context/portalContext";
import UserContextProvider from "../context/userContextProvider";
import { TeamQContextProvider } from "../context/teamQContextProvider";

export default function Portal(): JSX.Element {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3b86ff",
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <UserContextProvider>
        <PortalContextProvider>
          <Router>
            <Navbar />
            <Switch>
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <Route path="/teamq">
                <TeamQContextProvider>
                  <TeamQ />
                </TeamQContextProvider>
              </Route>
              <Route path="/case/:caseid">
                <Details />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/system-settings">
                <SystemSettings />
              </Route>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </Router>
        </PortalContextProvider>
      </UserContextProvider>
    </MuiThemeProvider>
  );
}
