import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ACD } from "../../../../../models/acd";
import { getFullACDs } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import ClientForm from "./clientForm";

const ClientSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("ACDHub"));
  const [data, setData] = useState<ACD[] | null>([]);

  useLoadData(
    getFullACDs,
    (data) => {
      setData(data as ACD[]);
    },
    hub,
    ["ACDCreated", "ACDUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((client) => {
      return {
        id: client.clientId,
        data: [
          client.clientCode,
          client.clientName,
          client.slaHours,
          client.amberThresholdHours,
          <Link key={`lis-${client.clientId}`} to={`/settings/acds/edit/${client.clientId}`}>
            Edit
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Client", "Name", "SLA", "Warning", ""]}
      rows={rows}
      titleSingle="Client"
      titlePlural="Clients"
      type={SettingsType.clients}
    >
    {data !== null && (
      <ClientForm 
        isDuplicate={(fieldToCheck, value): boolean=>{
          return isDuplicate(data.map((item)=>{
            return {
              id: item.clientId,
              value: fieldToCheck === "clientCode" ? item.clientCode : item.clientName,
            };
          }), value, Number(id));
        }}
       existingACD={data?.find((client) => client.clientId === Number(id))} 
       hub={hub}
      />
    )}
    </SettingsView>
  );
};
export default ClientSettings;
