import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IncomingEmailAddressRule } from "../../../../../models/incomingEmailAddressRule";
import { getIncomingEmailAddressRules } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsDelete from "../../shared/settingsDelete";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsAction, SettingsParams, SettingsType } from "../types";
import SystemAccountForm from "./systemAccountForm";

const SystemAccountSettings: FC = () => {
  const { action, id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("EmailAccountHub"));
  const [data, setData] = useState<IncomingEmailAddressRule[] | null>([]);

  useLoadData(
    getIncomingEmailAddressRules,
    (data) => {
      setData(data as IncomingEmailAddressRule[]);
    },
    hub,
    ["IncomingEmailAccountRuleCreated", "IncomingEmailAccountRuleUpdated", "IncomingEmailAccountRuleDeleted"]
  );

  const existingSystemAccount = data?.find((rule) => rule.incomingEmailAccountRuleId === Number(id));

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((rule) => {
      return {
        id: rule.incomingEmailAccountRuleId,
        data: [
          rule.emailAddress,
          rule.subject,
          rule.suppressAutoResponse ? "Yes" : "No",
          rule.classification?.name,
          rule.autoClose ? "Yes" : "No",
          rule.client?.clientName,
          <Link key={`lis-${rule.incomingEmailAccountRuleId}`} to={`/settings/systemaccounts/apply/${rule.incomingEmailAccountRuleId}`}>
            Apply Rule
          </Link>,
          <Link key={`lis-${rule.incomingEmailAccountRuleId}`} to={`/settings/systemaccounts/edit/${rule.incomingEmailAccountRuleId}`}>
            Edit
          </Link>,
          <Link key={`lis-${rule.incomingEmailAccountRuleId}`} to={`/settings/systemaccounts/delete/${rule.incomingEmailAccountRuleId}`}>
            Delete
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Email", "Subject", "Suppress ACK", "Default Classification", "Autoclose", "Default Client", ""]}
      rows={rows}
      titleSingle="System Account"
      titlePlural="System Accounts"
      type={SettingsType.systemAccounts}
    >
    {data !== null && action !== SettingsAction.delete && 
      <SystemAccountForm 
        isDuplicate={(fieldToCheck, value): boolean=>{
          return isDuplicate(data.map((item)=>{
            return {
              id: item.incomingEmailAccountRuleId,
              value: item.emailAddress + item.subject
            };
          }), value, Number(id));
        }}
       existingSystemAccount={existingSystemAccount} 
       hub={hub}
      />
    }
    {action === SettingsAction.delete && existingSystemAccount !== undefined &&
      <SettingsDelete 
        displayId={existingSystemAccount.emailAddress}
        deleteMessage={{
              type: "DeleteIncomingEmailRule",
              payload: {
                incomingEmailAccountRuleId: Number(id),
              },
            }}
        hub={hub}
        title="System Account"
      />
    }
    </SettingsView>
  );
};
export default SystemAccountSettings;
