import axios, { AxiosError, AxiosResponse } from "axios";
import { ResponseData } from "../models/data";
import { FullCase, RecentCaseReference, SummaryCase } from "../models/case";
import { User } from "../models/user";
import { ACD, Attachment, Classification, Team, TeamAccess } from "../models";
import { ClientName } from "../models/clientName";
import {
  apiUrl,
  axiosConfig,
  handleError,
  showError,
} from "./shared/dataServiceHelpers";
import { PageName } from "../context/teamQ/reducers/displaySettingsReducer";
import { Group } from "../models/group";
import { PollingAddress } from "../models/pollingAddress";
import { AiModel } from "../models/aiModel";
import { Department } from "../models/department";
import { IncomingEmailAddressRule } from "../models/incomingEmailAddressRule";
import { getToken } from "./auth.service";

const getDataByUrl = (url: string): Promise<ResponseData<unknown>> => {
  return axios
    .get(`${apiUrl}${url}`, axiosConfig())
    .then((axiosRes: AxiosResponse) => {
      const response: ResponseData<unknown> = axiosRes?.data;
      if (typeof response === "object" && !response.success) {
        showError(response.message || "Unknown error while getting data");
      }
      return response as ResponseData<unknown>;
    })
    .catch((error: AxiosError) => {
      handleError(error);
      return { message: error.message, success: false } as ResponseData<null>;
    });
};

export const getACDs = (): Promise<ResponseData<ACD[]>> => {
  return getDataByUrl("acds/getall") as Promise<ResponseData<ACD[]>>;
};

export const getFullACDs = (): Promise<ResponseData<ACD[]>> => {
  return getDataByUrl("acds/getallfull") as Promise<ResponseData<ACD[]>>;
};

export const getAiModels = (): Promise<ResponseData<AiModel[]>> => {
  return getDataByUrl("aimodels/getaimodels") as Promise<ResponseData<AiModel[]>>;
};

export const getAllCases = (
  page: PageName,
  queryString?: string
): Promise<ResponseData<SummaryCase[]>> => {
  const qString = queryString ? `/${queryString}` : "";
  const url = `cases/${page.toString()}${qString}${page !== PageName.GET_UNCLAIMED ? "&includeClosed=true" : ""}`;
  return getDataByUrl(url) as Promise<ResponseData<SummaryCase[]>>;
};

export const getAllUsers = (): Promise<ResponseData<User[]>> => {
  return getDataByUrl("users/getusers") as Promise<ResponseData<User[]>>;
};

export const getAttachment = (
  type: string,
  attachmentId: number
): Promise<ResponseData<Attachment>> => {
  return getDataByUrl(`attachments/getattachment/${type}/${attachmentId}`) as Promise<ResponseData<Attachment>>;
};

export const getAttachmentContent = (
  type: string,
  attachmentId: number,
): Promise<Blob> => {
  return axios
    .get(`${apiUrl}attachments/getattachmentcontent/${type}/${attachmentId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      responseType: 'blob'
    })
    .then((response) => {
      return response.data;
    });
};

export const getAttachments = (
  caseId: string
): Promise<ResponseData<unknown>> => {
  return getDataByUrl(`cases/getcaseattachments/${caseId}`);
};

export const getCasesById = (id: string): Promise<ResponseData<FullCase>> => {
  const url = `cases/getcase/${id}`;
  return getDataByUrl(url) as Promise<ResponseData<FullCase>>;
};

export const getCaseEmail = (
  caseId: string
): Promise<ResponseData<unknown>> => {
  return getDataByUrl(`cases/getcaseemails/${caseId}`);
};

export const getClients = (): Promise<ResponseData<ClientName[]>> => {
  return getDataByUrl("clients/getall") as Promise<ResponseData<ClientName[]>>;
};

export const getAllClassifications = (): Promise<ResponseData<Classification[]>> => {
  return getDataByUrl(`classifications/getall`) as Promise<
    ResponseData<Classification[]>
  >;
};

export const getClassificationsForUser = (): Promise<ResponseData<Classification[]>> => {
  return getDataByUrl(`classifications/getallforuser`) as Promise<
    ResponseData<Classification[]>
  >;
};

export const getClassificationsForCases = (
  selectedCases: string[]
): Promise<ResponseData<Classification[]>> => {
  return getDataByUrl(`classifications/getforcases/${selectedCases.join(",")}`) as Promise<
    ResponseData<Classification[]>
  >;
};

export const getDashboardData = (
  queryString: string
): Promise<ResponseData<any>> => {
  return getDataByUrl(`dashboard/getstats?${queryString}`);
};

export const getDepartments = (): Promise<ResponseData<Department[]>> => {
  return getDataByUrl("departments/getall") as Promise<ResponseData<Department[]>>;
};

export const getFilters = (): Promise<any> => {
  return getDataByUrl("users/getmyfilters");
};

export const getGroups = (): Promise<ResponseData<Group[]>> => {
  return getDataByUrl("groups/getgroups") as Promise<ResponseData<Group[]>>;
};

export const getGroupIdsForTeams = (
  teamIds: number[]
): Promise<ResponseData<number[]>> => {
  return getDataByUrl(`teams/getgroupidsforteams/${teamIds.join(",")}`) as Promise<ResponseData<number[]>>;
};

export const getIncomingEmailAddressRules = (): Promise<
  ResponseData<IncomingEmailAddressRule[]>
> => {
  return getDataByUrl("emailaccounts/getincomingemailaddressrules") as Promise<
    ResponseData<IncomingEmailAddressRule[]>
  >;
};

export const getIncomingEmailAddressRuleById = (ruleId: number): Promise<ResponseData<IncomingEmailAddressRule>> => {
  return getDataByUrl(`emailaccounts/getincomingemailaddressrule/${ruleId}`) as Promise<
    ResponseData<IncomingEmailAddressRule>
  >;
};

export const getPollingAddresses = (): Promise<
  ResponseData<PollingAddress[]>
> => {
  return getDataByUrl("emailaccounts/getpollingaddresses") as Promise<
    ResponseData<PollingAddress[]>
  >;
};

export const getPollingAddressesForUser = (): Promise<ResponseData<PollingAddress[]>> => {
  return getDataByUrl(`emailaccounts/getpollingaddressesforuser`) as Promise<
    ResponseData<PollingAddress[]>
  >;
};

export const getRecentCases = (): Promise<
  ResponseData<RecentCaseReference[]>
> => {
  return getDataByUrl("users/getmyrecentlyviewed") as Promise<
    ResponseData<RecentCaseReference[]>
  >;
};

export const getSettingsData = (
  url: string
): Promise<ResponseData<unknown>> => {
  return getDataByUrl(url);
};

export const GetTeamAccesses = (): Promise<ResponseData<TeamAccess[]>> => {
  return getDataByUrl("teamaccesses/getteamaccesses") as Promise<
    ResponseData<TeamAccess[]>
  >;
};

export const getTeams = (): Promise<ResponseData<Team[]>> => {
  return getDataByUrl("teams/getteams") as Promise<ResponseData<Team[]>>;
};

export const getTeamsForGroups = (
  groupIds: number[]
): Promise<ResponseData<Team[]>> => {
  return getDataByUrl(`teams/getteamsforgroups/${groupIds.join(",")}`) as Promise<ResponseData<Team[]>>;
};

export const getUsersByTeam = (
  teamId: number
): Promise<ResponseData<User[]>> => {
  return getDataByUrl(`users/getusersbyteam/${teamId}`) as Promise<
    ResponseData<User[]>
  >;
};

export const getUserImage = (): Promise<ResponseData<string>> => {
  return getDataByUrl("authentication/getphoto") as Promise<ResponseData<string>>;
};

export const getUsers = (): Promise<ResponseData<User[]>> => {
  return getDataByUrl("users/getusers") as Promise<ResponseData<User[]>>;
};

export const upsertLogin = (): Promise<ResponseData<User>> => {
  return axios
    .get(`${apiUrl}authentication/login`, axiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch(handleError);
};
