import { Reducer } from "react";

export enum selectedCasesAction {TOGGLE_ONE, TOGGLE_ALL, REMOVE_ALL}

export type toggleOneAction = {
  type: selectedCasesAction.TOGGLE_ONE;
    caseReference: string;
}

export type toggleAllAction = {
  type: selectedCasesAction.TOGGLE_ALL
    caseReferences: string[];
}

export type removeAllAction = {
  type: selectedCasesAction.REMOVE_ALL;
}

const selectedCasesReducer: Reducer<string[], toggleOneAction|toggleAllAction|removeAllAction> = (state, action) => {
  switch (action.type) {
    case selectedCasesAction.TOGGLE_ONE: {  
      const index = state.indexOf(action.caseReference);
      const mutableState = [...state];
      if (index !== -1) {
        mutableState.splice(index, 1);
      } else {
        mutableState.push(action.caseReference);
      }
      return mutableState;
    }
    case selectedCasesAction.REMOVE_ALL: {
      return [];
    }
    case selectedCasesAction.TOGGLE_ALL: {
      if (action.caseReferences.length === state.length) {
        return [];
      } else {
        return action.caseReferences;
      }
    }
  }
}
export default selectedCasesReducer;