import { useEffect, useRef } from "react";

const pollingIntervalSeconds = 60;

const usePolling = (getCaseData: () => void): void => {

  const savedCallback = useRef<() => void>(getCaseData);
  useEffect(() => {
    const id = setInterval(savedCallback.current, pollingIntervalSeconds * 1000);
    return (): void => clearInterval(id);    
  }, [] );
}
export default usePolling;

