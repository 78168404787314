import React, { FC } from "react";

type Props = {
  label: string;
  name: string;
  onChange: (value: string) => void;
  value: string;  
};

const EmailInput: FC<Props> = ({ label, name, onChange, value}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type="email"
        className="form-control"
        name={name}
        id={name}
        value={value}
        onChange={(event):void => onChange(event.currentTarget.value)}
      />
    </div>);
};
export default EmailInput;