import React from "react";
import { PageName } from "../../../context/teamQ/reducers/displaySettingsReducer";

export interface TeamQTabProps {
  changeTab: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: PageName,
    setPage: (page: PageName) => void
  ) => void;
  setPage: (page: PageName) => void;
  page: PageName;
  route: PageName;
  title: string;
  totalCases?: number;
}

export default function TeamQTab(props: TeamQTabProps) {
  const { changeTab, page, setPage, route, title, totalCases } = props;
  let tabAmount = "";

  // We don't show an amount next to unclaimed cases tab
  if (totalCases !== undefined) {
    tabAmount = totalCases !== -1 ? "(" + totalCases + ")" : "(...)";
  }

  return (
    <li>
      <button
        data-test="teamQTab"
        className={page === route ? "active" : ""}
        onClick={(event) => changeTab(event, route, setPage)}
      >
        {tabAmount !== "" ? title + " " + tabAmount : title}
      </button>
    </li>
  );
}
