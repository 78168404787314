import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import Portal from "./portal/portal";
import * as serviceWorker from "./serviceWorker";

export const runApp = () => {
  ReactDOM.render(<Portal />, document.getElementById("root"));

  serviceWorker.unregister();
};
