import React, { FC, useState } from "react";
import { getQueryString } from "../../../context/teamQ/helpers/getQueryString";
import { displaySettingsAction } from "../../../context/teamQ/reducers/displaySettingsReducer";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { useUserContext } from "../../../context/userContextProvider";
import {SaveFilterModal} from "../../shared/actionModals/saveFilterModal";

export type selectOptionsType = { id: string; label: string };

export const FilterActionButtons: FC = () => {
  const {
    state: { filters, displaySettings },
    dispatch: { displaySettings: displaySettingsDispatch },
    actions: {load},
  } = useTeamQContext();
  const userContext = useUserContext();

  const [showSave, setShowSave] = useState(false);

  const handleSave = (
    filterName: string
  ): void => {
    setShowSave(false);
    const queryString: string = getQueryString(filters, displaySettings.sort);
    userContext.actions.saveFilter(queryString, filterName);
  };

  return (
    <div className="row">
      <div className="col-sm-6 pr-1">
        <button
          className="btn btn-light btn-block btn-sm"
          onClick={(): void => {
            displaySettingsDispatch({
              type: displaySettingsAction.CHANGE_PAGE_NUMBER,
              pageIndex: "0",
            });
            load();
          }}
        >
          Search
        </button>
      </div>
      <div className="col-sm-6 pl-1">
        <button className="btn btn-light btn-block btn-sm" onClick={(): void => setShowSave(true)}>
          Save
        </button>
      </div>

      <SaveFilterModal
        show={showSave}
        handleClose={(): void => setShowSave(false)}
        handleSave={handleSave}
      />
    </div>
  );
};
