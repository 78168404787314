import React from "react";
import { useParams } from "react-router-dom";
import {
  CasesDetailsConsumer,
  CasesDetailsContextProvider,
} from "../../../context/casesDetailsContextProvider";
import CaseDetailsSidenav from "./shared/sidenav";
import Layout from "./shared/layout";

export default function Details() {
  const { caseid } = useParams();

  return (
    <CasesDetailsContextProvider caseId={caseid}>
      <CasesDetailsConsumer>
        {(context) => {
          return (
            <div className="page-layout">
              <CaseDetailsSidenav></CaseDetailsSidenav>
              <Layout state={context.state} />
            </div>
          );
        }}
      </CasesDetailsConsumer>
    </CasesDetailsContextProvider>
  );
}
