import React, { Component } from "react";
import { UserConsumer } from "../../../context/userContextProvider";
import { NavLink } from "react-router-dom";

export default class UserMenu extends Component {
  node: any;
  state = {
    isOpen: false,
  };

  toggleMenu = (event: any) => {
    event.preventDefault();

    this.setState((state: { isOpen: boolean }) => ({
      isOpen: !state.isOpen,
    }));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e: any) => {
    if (!this.node) {
      return false;
    }
    if (this.node.contains(e.target)) {
      return false;
    }

    this.setState({
      isOpen: false,
    });
  };

  close = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <UserConsumer>
        {(context) => (
          <>
            {context.state.user ? (
              <li
                className={this.state.isOpen ? "dropdown" : "dropdown closed"}
                ref={(node) => (this.node = node)}
              >
                <button
                  className="nofocus user"
                  onClick={(e) => this.toggleMenu(e)}
                >
                  <span className="name" data-cy="navbar-username">
                    {context.state.user.firstName} {context.state.user.lastName}
                  </span>
                  <i className="fas fa-chevron-down"></i>

                  {context.state.userImage ? (
                    <img
                      className="profile-pic"
                      src={`data:image/gif;base64,${context.state.userImage}`}
                      alt={`${context.state.user.firstName} ${context.state.user.lastName} Profile`}
                    />
                  ) : (
                    <div className="default-profile-pic">
                      {context.state.user.firstName.charAt(0)}
                      {context.state.user.lastName.charAt(0)}
                    </div>
                  )}
                </button>

                <div className="menu-dropdown menu-dropdown-small">
                  <div className="menu-dropdown-content">
                    <ul className="account-menu">
                      {context.state.user.isManager ? (
                        <li>
                          <NavLink onClick={() => this.close()} to="/settings">
                            Settings
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                      {context.state.user.isSysAdmin ? (
                        <li>
                          <NavLink
                            onClick={() => this.close()}
                            to="/system-settings"
                          >
                            System Settings
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <div className="version">
                          Version:{" "}
                          {process.env.REACT_APP_SYSTEM_VERSION || "0.0.0"}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
          </>
        )}
      </UserConsumer>
    );
  }
}
