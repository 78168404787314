import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { ResultsTableRowData, SettingsAction, SettingsParams, SettingsType } from "../pages/types";
import SettingsList from "./settingsList";

type Props = {
  headers: string[];
  rows: ResultsTableRowData[] | null;
  sortColumn?: number;
  titleSingle: string;
  titlePlural: string;
  type: SettingsType;
};

const SettingsView: FC<Props> = ({ headers, rows, sortColumn, titleSingle, titlePlural, type, children }) => {
  const { action }: SettingsParams = useParams();
  return (
    <>
      {action === SettingsAction.list && rows && (
        <SettingsList
          headers={headers}
          rows={rows}
          sortColumn={sortColumn}
          titleSingle={titleSingle}
          titlePlural={titlePlural}
          type={type}
        />
      )}
      {(action === SettingsAction.create || action === SettingsAction.edit || action === SettingsAction.delete) 
        && <>{children}</>
      }
    </>
  );
};
export default SettingsView;
