import React, { FC } from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import SettingsSidenav from "./settingsSidenav";
import {
  useUserContext,
} from "../../../context/userContextProvider";
import TeamSettings from "./pages/teamSettings/teamSettings";
import UserSettings from "./pages/userSettings/userSettings";
import SystemAccountSettings from "./pages/systemAccountSettings/systemAccountSettings";
import SettingsApplyRule from "./pages/settingsApplyRule";
import ClassificationSettings from "./pages/classificationSettings/classificationSettings";
import ClientSettings from "./pages/clientSettings/clientSettings";

const Settings: FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const {state: {user}} = useUserContext();
  
  return (
    <>
      {user ?
        user.isManager ? (
          <div className="page-layout">
            <SettingsSidenav />
            <div className="content">              
              <Switch>
                <Route
                  path={`${path}/acds/:action/:id?`}
                  component={ClientSettings}
                />
                <Route
                  path={`${path}/classifications/:action/:id?`}
                  component={ClassificationSettings}
                />
                <Route
                  path={`${path}/teams/:action/:id?`}
                  component={TeamSettings}
                />
                <Route
                  path={`${path}/systemaccounts/apply/:id?`}
                  component={SettingsApplyRule}
                />
                <Route
                  path={`${path}/systemaccounts/:action/:id?`}
                  component={SystemAccountSettings}
                />
                <Route
                  path={`${path}/users/:action/:id?`}
                  component={UserSettings}
                />
                <Route exact path={`${path}/:type`} key={location.pathname}>
                  <Redirect
                    to={`${location.pathname}${
                      location.pathname.endsWith("/") ? "" : "/"
                    }list`}
                  ></Redirect>
                </Route>
                <Route exact path={`${path}/`} key={location.pathname}>
                  <Redirect to={`${path}/users/list`}></Redirect>
                </Route>
              </Switch>
            </div>
          </div>
        ) : (
          <Redirect to="" />
        )
      : null
      }
    </>
  );
}
export default Settings;