import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AiModel } from "../../../../../models";
import HubService from "../../../../../services/hub.service";
import TextInput from "../../shared/fields/textInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";

type Props = {
  isDuplicate: (fieldToCheck: keyof AiModel, value: string)=> boolean;
  existingAiModel?: AiModel;
  hub: HubService;
};

const AiModelForm: FC<Props> = ({ isDuplicate, existingAiModel, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [classifierPath, setClassifierPath] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existingAiModel) {
      setName(existingAiModel.name);
      setClassifierPath(existingAiModel.classifierPath ? existingAiModel.classifierPath : "");
    }
  }, [existingAiModel]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (name.length === 0) {
      errorMessages.push("Name is required");
    } else {
      if (isDuplicate("name", name)) {
        errorMessages.push("Name has already been used");
      }
    }
    if (classifierPath.length === 0) {
      errorMessages.push("Classifier Path is required");
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existingAiModel) {
        sendSetting(hub, {
            type: "UpdateAiModel",
            payload: {
              aiModelId: existingAiModel.aiModelId,
              name: name.trim(),
              classifierPath: classifierPath.trim(),
            },
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "AddAiModel",
            payload: {
              name: name.trim(),
              classifierPath: classifierPath.trim(),
            },
          }, setRedirect);
      }
    }
  };

  if (redirect) return <Redirect to="/system-settings/aiModels/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingAiModel ? "Edit AiModel" : "Create AiModel"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingAiModel?.name} >
          {existingAiModel?.classifierPath}
        </SettingsFormInfo>
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput 
              label="Name" 
              name="aiModel-name" 
              onChange={setName} 
              value={name} 
            />
            <TextInput 
              label="Classifier Path" 
              name="classifier-path" 
              onChange={setClassifierPath} 
              value={classifierPath} 
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AiModelForm;
