import React, { FC, useEffect, useState } from "react";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { DashboardData, DashboardDataDetails, slaType, DashboardDataDetailsStats } from "../../../../context/dashboard/dashboard";


Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

interface Props {
  data: DashboardData;
  position: number;
}

type Stats = {
  greenData: number;
  amberData: number;
  redData: number;
};

type ClassificationStats = Record<string, Stats>;

const emptyStats: Stats = {
  greenData: 0,
  amberData: 0,
  redData: 0,
};

const SlaChart: FC<Props> = ({ data, position }) => {
  const [chartHeight, setChartHeight] = useState<number>(0);
  const stats: ClassificationStats = {};

  const filteredStats: DashboardDataDetails[] = data.details;

  filteredStats.forEach((slaTypeSummary: DashboardDataDetails) => {
    switch (slaTypeSummary.slaType) {
      case slaType.within:
        slaTypeSummary.stats.forEach((totalByClassification: DashboardDataDetailsStats) => {
          if (totalByClassification.cases) {
            const label = totalByClassification.heading;
            if (stats[label] === undefined) stats[label] = {...emptyStats};
            stats[label].greenData = totalByClassification.cases;
          }
        });
        break;
      case slaType.amber:
        slaTypeSummary.stats.forEach((totalByClassification: DashboardDataDetailsStats) => {
          if (totalByClassification.cases) {
            const label = totalByClassification.heading;
            if (stats[label] === undefined) stats[label] = {...emptyStats};
            stats[label].amberData = totalByClassification.cases;
          }
        });
        break;
      case slaType.breach:
        slaTypeSummary.stats.forEach((totalByClassification: DashboardDataDetailsStats) => {
          if (totalByClassification.cases) {
            const label = totalByClassification.heading;
            if (stats[label] === undefined) stats[label] = {...emptyStats};
            stats[label].redData = totalByClassification.cases;
          }
        });
        break;
      default:
        console.error("Incorrect SLA Type detected");
    }
  });

  let slaChart: Chart;
  useEffect(() => {
    if (slaChart) {
      slaChart.destroy();
    }
    const labels: string[] = Object.keys(stats);
    slaChart = new Chart(`sla-chart-${position}`, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            barPercentage: 1,
            maxBarThickness: 15,
            backgroundColor: "#25A45D",
            data: Object.values(stats).map((stat) => stat.greenData),
          },
          {
            barPercentage: 1,
            maxBarThickness: 15,
            backgroundColor: "#ffc107",
            data: Object.values(stats).map((stat) => stat.amberData),
          },
          {
            barPercentage: 1,
            maxBarThickness: 15,
            backgroundColor: "#EF626C",
            data: Object.values(stats).map((stat) => stat.redData),
          },
        ],
      },
      options: {
        font: { family: "Source Sans Pro", size: 11 },
        indexAxis: "y",
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            ticks: {
              callback: (tick: string | number): string | number => {
                if (tick.toString().indexOf(".") > 0) {
                  return ""; // Don't show 0.1, 0.2 of a case
                } else {
                  return tick;
                }
              },
            },
          },
        },
      },
    });
    setChartHeight(labels.length > 8 ? labels.length * 37 : 290);
    return (): void => {
      if (slaChart) {
        slaChart.destroy();
      }
    };
  }, [data]);

  return (
    <div className="chart-flex">
      <div className="chart-section">
        <div className="chart-relative">
          <canvas
            id={`sla-chart-${position}`}
            style={{ height: chartHeight }}
          ></canvas>
        </div>
      </div>
    </div>
  );
};

export default SlaChart;
