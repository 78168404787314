import React from "react";
import SlaSummaryChart from "./charts/slaSummary";
import SlaChart from "./charts/slaChart";

import { DashboardConsumer } from "../../../context/dashboardContextProvider";
import { DashboardContext, DashboardData } from "../../../context/dashboard/dashboard";

const DashboardSLACharts = () => {
  return (
    <DashboardConsumer>
      {(context: DashboardContext) => {
        return (
          <>
            {context.state.dashboardData?.map(
              (dashboard: DashboardData, index: number) => {
                return (
                  <div className="row mb-5" key={index}>
                    <div className="col-3">
                      <div className="content-container chart-padding">
                        <h2 className="chart-heading">
                          Total Cases by Service Level
                        </h2>
                        <div className="sla-summary-chart-header">
                          {dashboard.acd === "All"
                            ? "All Clients"
                            : dashboard.acd}
                        </div>
                        <SlaSummaryChart
                          data={dashboard}
                          position={index}
                        ></SlaSummaryChart>
                      </div>
                    </div>
                    <div className="col-9">
                      <div
                        className={`content-container chart-padding ${
                          dashboard.totalCases === 0 ? "chart-center" : ""
                        }`}
                      >
                        <h2 className="chart-heading">
                          Cases{" "}
                          {dashboard.acd === "All"
                            ? "by Client"
                            : ` for ${dashboard.acd}`}{" "}
                          by{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {context.state.filters.type}
                          </span>
                        </h2>
                        {dashboard.totalCases > 0 ? (
                          <SlaChart
                            data={dashboard}
                            position={index}
                          ></SlaChart>
                        ) : (
                          <h1>There are no cases for this Client</h1>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </>
        );
      }}
    </DashboardConsumer>
  );
};

export default DashboardSLACharts;
