import React, { FC } from "react";
import { FormControl, MenuItem } from "@material-ui/core";
import Dropdown from "../../components/dropdown";

type Choice = { id: number; label: string };

interface Props {
  choices: Array<Choice>;
  handleIdChange: (id: string) => void;
  handleNoteChange: (note: string) => void;
  id: string;
  note: string;
  placeholder: string;
  title: string;
}

export const ConfirmInputGroup: FC<Props> = ({
  choices,
  handleIdChange,
  handleNoteChange,
  id,
  note,
  placeholder,
  title,
}: Props) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="choices">{title}</label>

        <FormControl>
          <Dropdown name="choices" onChange={(id: string): void => handleIdChange(id)} value={`${id}`}>
            <MenuItem value="">{placeholder}</MenuItem>
            {choices.map((choice: Choice, index: number) => (
              <MenuItem value={choice.id} key={index}>
                {choice.label}
              </MenuItem>
            ))}
          </Dropdown>
        </FormControl>
      </div>
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <textarea
          className="form-control"
          name="note"
          id="note"
          value={note}
          onChange={(event): void => handleNoteChange(event.currentTarget.value as string)}
        ></textarea>
      </div>
    </>
  );
};
