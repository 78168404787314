import {
  DisplaySettings,
  displaySettingsAction,
  PageName,
  PaginationSettings,
  replaceAllAction,
  SortDirection,
} from "../reducers/displaySettingsReducer";
import {
  FilterName,
  Filters,
  FiltersAction,
  filtersInitialState,
  replaceFiltersAction,
} from "../reducers/filtersReducer";

export interface LocalStorageSettings {
  filters: Filters;
  displaySettings: DisplaySettings;
}

interface LegacyLocalStorageSettings {
  appliedFilters: legacyLocalStorageFilter[];
  sort: legacySortSettings[];
  page: string;
  pagination: PaginationSettings;
}
type legacyLocalStorageFilter = {
  name: string;
  value: string;
  label: unknown;
};
type legacySortSettings = {
  name: string;
  value: string;
};

export const readLocalStorage = (
  displaySettingsDispatch: (action: replaceAllAction) => void,
  filtersDispatch: (action: replaceFiltersAction) => void
): void => {
  const local: string | null = localStorage.getItem("caseState");
  if (local) {
    const data: unknown = JSON.parse(local);
    // @TODO remove in next release
    // If settings are in the legacy format convert them to LocalStorageSettings
    // @TODO check whether changed filters are in prod
    const legacySettings = data as LegacyLocalStorageSettings;
    let newFilters: Filters = { ...filtersInitialState };
    let newDisplaySettings: DisplaySettings;
    if (legacySettings?.appliedFilters?.[0]?.name === "caseReference") {
      for (const key in newFilters) {
        const filter = legacySettings.appliedFilters.find((filter: legacyLocalStorageFilter) => {
          return filter.name === key;
        });
        if (filter !== undefined) {
          newFilters[key as FilterName].value = filter.value;
          newFilters[key as FilterName].show = true;
        }
      }
      newDisplaySettings = {
        pageName: legacySettings.page as PageName,
        pagination: legacySettings.pagination,
        sort: {
          headerToSort: legacySettings.sort[0].name,
          direction: legacySettings.sort[0].value as SortDirection,
        },
      };
    } else {
      const settings = data as LocalStorageSettings;
      newFilters = settings.filters;
      newDisplaySettings = settings.displaySettings;
    }
    filtersDispatch({
      type: FiltersAction.REPLACE,
      newFilters,
    });
    displaySettingsDispatch({
      type: displaySettingsAction.REPLACE_ALL,
      newSettings: newDisplaySettings,
    });
  }
};

export const writeLocalStorage = (
  displaySettings: DisplaySettings,
  filters: Filters,
): void => {
  const storedDisplaySettings = { ...displaySettings };
  storedDisplaySettings.pagination.pageIndex = 0;
  const settings: LocalStorageSettings = {
    filters,
    displaySettings: storedDisplaySettings,
  };
  localStorage.setItem("caseState", JSON.stringify(settings));
}