import { Checkbox } from "@material-ui/core";
import React, { FC } from "react";
import { displaySettingsAction, SortDirection, SortSettings } from "../../../context/teamQ/reducers/displaySettingsReducer";
import { FilterName, Filters, FiltersAction } from "../../../context/teamQ/reducers/filtersReducer";
import { useTeamQContext } from "../../../context/teamQContextProvider";
import { useUserContext } from "../../../context/userContextProvider";
import { SavedFilter } from "../../../models";

export type selectOptionsType = { id: string; label: string };
interface Props {
  checkBoxChecked: number | null;
  setCheckBoxChecked: (checkBoxChecked: number) => void;
}

const getFiltersFromQueryString = (filters: Filters, queryString: string): Filters => {
  const filterQuery: string = queryString.split("&")[0];
  if (filterQuery.substring(0, 9) === "filterBy=") {    
    const filterSplit: string[] = filterQuery.replace("filterBy=", "").split(",");
    filterSplit.forEach((filterPair: string) => {
      if (filterPair.length > 0) {
        const [filterName, filterValue] = filterPair.split("|");
        filters[filterName as FilterName].value = decodeURIComponent(filterValue);
        filters[filterName as FilterName].show = true;
      }
    });
  }
  return filters;
};

const getSortFromQueryString = (sort: SortSettings, queryString: string): SortSettings => {
  const sortQuery: string = queryString.split("&")[1];
  if (sortQuery && sortQuery.substring(0, 8) === "orderBy=") {
    const sortPair: string = sortQuery.replace("orderBy=", ""); 
    const [headerToSort, direction] = sortPair.split("|");
    sort.headerToSort = headerToSort;
    sort.direction = direction as SortDirection
  }
  return sort;
};

export const SavedFilters: FC<Props> = ({ checkBoxChecked, setCheckBoxChecked }) => {
  const {
    state: {
      filters,
      displaySettings: { sort },
    },
    dispatch: { displaySettings: displaySettingsDispatch, filters: filtersDispatch },
  } = useTeamQContext();
  const userContext = useUserContext();

  return (
    <>
      {userContext.state.savedFilters.length > 0 && <div className="title mt-3">Saved Filters</div>}
      <div className="row">
        {userContext?.state.savedFilters.map((filter: SavedFilter, index: number) => (
          <div className="col-12" key={index}>
            <div className="flex-filters">
              <div className="filter-label">
                <Checkbox
                  checked={checkBoxChecked === index}
                  onClick={(): void => {
                    setCheckBoxChecked(index);
                    filtersDispatch({
                      type: FiltersAction.REPLACE,
                      newFilters: getFiltersFromQueryString(filters, filter.queryString),
                    });
                    displaySettingsDispatch({
                      type: displaySettingsAction.REPLACE_SORT,
                      newSort: getSortFromQueryString(sort, filter.queryString),
                    });
                  }}
                />
                {filter.filterName}
              </div>
              <button className="delete-button" onClick={(): void => userContext.actions.deleteFilter(filter.filterId)}>
                <i className="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
