import { selectOptionsType } from "../../../components/Inputs/SingleSelect";
import { ACD, User, Classification, Team } from "../../../models";
import {
  DashboardACD,
  DashboardCaseByUser,
  DashboardUser,
} from "../../../context/dashboard/dashboard";

export const getAlphabetisedAcdList = (acdList: ACD[]): selectOptionsType[] => {
  return acdList
    .sort((a: ACD, b: ACD) =>
      a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase())
    )
    .map(
      (client: ACD): selectOptionsType => {
        return {
          id: `${client.clientId}`,
          label: client.clientName,
        };
      }
    );
};

export const getAlphabetisedDashboardAcdList = (
  dashboardAcdList: DashboardACD[]
): DashboardACD[] => {
  return dashboardAcdList.sort((a: DashboardACD, b: DashboardACD) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
};

export const getAlphabetisedUserList = (
  userList: User[]
): selectOptionsType[] => {
  return userList
    .sort((a: User, b: User) =>
      (a.firstName + " " + a.lastName)
        .toLowerCase()
        .localeCompare((b.firstName + " " + b.lastName).toLowerCase())
    )
    .map(
      (user: User): selectOptionsType => {
        return {
          id: `${user.id}`,
          label: `${user.firstName} ${user.lastName}`,
        };
      }
    );
};

// Needed until dashboard can implement the same user type as TeamQ
export const getAlphabetisedDashboardUserList = (
  userList: DashboardUser[]
): DashboardUser[] =>
  userList.sort((a: DashboardUser, b: DashboardUser) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );

export const getAlphabetisedTeamList = (
  teamList: Team[]
): selectOptionsType[] => {
  return teamList
    .sort((a: Team, b: Team) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    )
    .map(
      (team: Team): selectOptionsType => {
        return {
          id: team.name,
          label: team.name,
        };
      }
    );
};

export const getAlphabetisedClassificationList = (
  classList: Classification[]
): selectOptionsType[] => {
  return classList
    .sort((a: Classification, b: Classification) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    )
    .map(
      (classification: Classification): selectOptionsType => {
        return {
          id: classification.name,
          label: classification.name,
        };
      }
    );
};

export const getAlphabetisedDashboardCasesByUsersList = (
  casesByUserList: DashboardCaseByUser[]
): DashboardCaseByUser[] =>
  casesByUserList.sort((a: DashboardCaseByUser, b: DashboardCaseByUser) =>
    a.userName.toLowerCase().localeCompare(b.userName.toLowerCase())
  );
