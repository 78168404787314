import { Select } from "@material-ui/core";

import React, { FC } from "react";
import { BootstrapInput } from "./bootstrapInput";

type Props = {
  name: string;
  onChange: (
    value: string,
  ) => void;
  value: string;
};

const Dropdown: FC<Props> = ({ children, name, onChange, value }) => {

  return (
    <Select 
      id={name} 
      name={name} 
      displayEmpty 
      input={<BootstrapInput />} 
      value={value} 
      onChange={
        (event):void=> {
          onChange(event.target.value as string);
      }}
    >
      {children}
    </Select>
  );
};

export default Dropdown;
