import React from "react";
// import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  withStyles,
  InputBase,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  getAlphabetisedDashboardAcdList,
  getAlphabetisedDashboardUserList,
} from "../../shared/helpers/sorting";

import { Sidenav } from "../../shared/sidenav/sidenav";
import { DashboardConsumer } from "../../../context/dashboardContextProvider";

export default function DashboardSidenav() {
  const BootstrapInput = withStyles((theme) => ({
    root: {
      width: "100%",
      display: "block",
    },
    input: {
      display: "block",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: ["Source Sans Pro"].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  return (
    <DashboardConsumer>
      {(context: any) => {
        return (
          <Sidenav>
            <div className="title">
              Filter Dashboard
              <button
                onClick={context.actions.clearData}
                className="btn btn-danger btn-rounded float-right align-bottom"
              >
                Clear
              </button>
            </div>

            <div className="form-group">
              <label htmlFor="select-acd">Select Clients</label>
              <Typeahead
                id="select-acd"
                multiple
                placeholder="Select Client"
                onChange={context.actions.changeACDs}
                selected={context.state.selectedACDs}
                options={
                  context.state.acds !== []
                    ? getAlphabetisedDashboardAcdList(context.state.acds)
                    : []
                }
              />
            </div>

            {context.state.filters.status !== "open" ? (
              <div className="form-group">
                <label htmlFor="data-period">Data Period</label>
                <FormControl>
                  <Select
                    id="data-period"
                    displayEmpty
                    name="period"
                    input={<BootstrapInput />}
                    onChange={(event: any) =>
                      context.actions.setFilters("period", event.target.value)
                    }
                    value={context.state.filters.period}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="current-month">Current Month</MenuItem>
                    <MenuItem value="last-month">Last Month</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              <label htmlFor="data-type">Data Type</label>

              <Select
                id="data-type"
                displayEmpty
                name="type"
                input={<BootstrapInput />}
                onChange={(event: any) =>
                  context.actions.setFilters("type", event.target.value)
                }
                value={context.state.filters.type}
              >
                <MenuItem value="classification">Classification</MenuItem>
                <MenuItem value="team">Team</MenuItem>
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="status">Status</label>
              <Select
                id="status"
                displayEmpty
                name="status"
                input={<BootstrapInput />}
                onChange={(event: any) =>
                  context.actions.setFilters("status", event.target.value)
                }
                value={context.state.filters.status}
              >
                <MenuItem value="awaiting">Awaiting</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="open">Open</MenuItem>
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="select-users">Users</label>
              <Typeahead
                id="select-users"
                multiple
                placeholder="Select Users"
                onChange={context.actions.changeUsers}
                selected={context.state.selectedUsers}
                options={
                  context.state.users !== []
                    ? getAlphabetisedDashboardUserList(context.state.users)
                    : []
                }
              />
            </div>
          </Sidenav>
        );
      }}
    </DashboardConsumer>
  );
}
