import React from "react";
import HtmlSanitiser from "../../../shared/htmlSanitiser";

export default function Bubble(props: any) {
  return (
    <>
      <div
        className={`bubble bubble-${props.direction} bubble-${props.type} ${
          props.sending === "sending" ? "bubble-sending" : ""
        }`}
      >
        <HtmlSanitiser content={props.children}></HtmlSanitiser>
      </div>
      <div className={`bubble-external bubble-external-${props.direction}`}>
        {props.external}
      </div>
    </>
  );
}
