import React, { FC } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { InputChangeEvent } from "./types";
import { Filter, FilterName } from "../../context/teamQ/reducers/filtersReducer";
import { BootstrapInput } from "../../portal/shared/components/bootstrapInput";

export type selectOptionsType = { id: string; label: string };
interface Props {
  filterName: FilterName;
  filter: Filter;
  options: selectOptionsType[];
  handleFilter: (event: InputChangeEvent) => void;
}

export const SingleSelect: FC<Props> = ({ filterName, filter, options, handleFilter }) => {
  if (!filter.show) return null;

  return (
    <div className="form-group">
      <label htmlFor={filterName}>{filter.label}</label>

      <FormControl>
        <Select
          id={filterName}
          labelId={`${filterName}-label`}
          displayEmpty
          name={filterName}
          input={<BootstrapInput />}
          onChange={(e): void => handleFilter(e as InputChangeEvent)}
          value={filter.value}
        >
          <MenuItem value="">Select one</MenuItem>

          {options.map((item: selectOptionsType, index: number) => (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
