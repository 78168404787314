import { SendParams } from "../../../../../models";
import HubService from "../../../../../services/hub.service";

const sendSetting = (hub: HubService, message: SendParams, setRedirect: (state: boolean)=>void): void =>{
  hub
    .send(message)
    .then(() => {
      setRedirect(true);
    })
    .catch();
}
export default sendSetting;