import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiModel } from "../../../../../models/aiModel";
import { getAiModels } from "../../../../../services/get.data.service";
import HubService from "../../../../../services/hub.service";
import isDuplicate from "../../shared/helpers/isDuplicate";
import useLoadData from "../../shared/hooks/useLoadData";
import SettingsView from "../../shared/settingsView";
import { ResultsTableRowData, SettingsParams, SettingsType } from "../types";
import AiModelForm from "./aiModelForm";

const AiModelSettings: FC = () => {
  const { id }: SettingsParams = useParams();
  const [hub] = useState<HubService>(new HubService("AiModelHub"));
  const [data, setData] = useState<AiModel[] | null>([]);

  useLoadData(
    getAiModels,
    (data) => {
      setData(data as AiModel[]);
    },
    hub,
    ["AiModelCreated", "AiModelUpdated"]
  );

  const rows: ResultsTableRowData[] | null =
    data &&
    data.map((aiModel) => {
      return {
        id: aiModel.aiModelId,
        data: [
          aiModel.name,
          aiModel.classifierPath,
          <Link key={`lis-${aiModel.aiModelId}`} to={`/system-settings/aiModels/edit/${aiModel.aiModelId}`}>
            Edit
          </Link>,
        ],
      };
    });
  return (
    <SettingsView
      headers={["Name", "Classifier Path", ""]}
      rows={rows}
      titleSingle="AiModel"
      titlePlural="AiModels"
      type={SettingsType.aiModels}
    >
    {data !== null && (
      <AiModelForm 
        isDuplicate={(fieldToCheck, value): boolean=>{
          return isDuplicate(data.map((item)=>{
            return {
              id: item.aiModelId,
              value: fieldToCheck === "name" ? item.name : item.classifierPath,
            };
          }), value, Number(id));
        }}
       existingAiModel={data?.find((aiModel) => aiModel.aiModelId === Number(id))} 
       hub={hub}
      />
    )}
    </SettingsView>
  );
};
export default AiModelSettings;
