import React, { FC, useContext } from "react";
import { NavLink } from "react-router-dom";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { logOut } from "../../../services/auth.service";
import { PortalContext } from "../../../context/portalContext";
// import { UserConsumer } from "../../../context/userContextProvider";

import CasesMenu from "./cases";
import UserMenu from "./user";
import { businessAreaSettings } from "../../helpers/businessAreaSettings";
// import { UserContext } from "../../../models";

export const Navbar: FC = () => {
  const { isSidenavOpen, updateSidenavOpen } = useContext(PortalContext);
  return (
    <div className="navbar">
      <div className="brand">
        <div className="logo">FNZ Æ</div>
        <i
          role="button"
          onClick={(): void => updateSidenavOpen(!isSidenavOpen)}
          data-cy="sidenav-toggle"
          className="fas fa-bars"
        ></i>
      </div>
      <div className="nav">
        <ul className="menu">
          <li>
            <NavLink activeClassName="active" to="/dashboard">
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/teamq">
              Team Q
            </NavLink>
          </li>
          {/* <UserConsumer>
            {(context: UserContext) =>
              context.state.user?.isSysAdmin && (
                <li>
                  <NavLink activeClassName="active" to="/scanx" data-cy="scanx-navlink">
                    ScanX
                  </NavLink>
                </li>
              )
            }
          </UserConsumer> */}
        </ul>

        <ul className="menu-right">
          {businessAreaSettings().userGuideUrl.length > 0 && (
            <li>
              <a href={businessAreaSettings().userGuideUrl} target="_userguide">
                <InfoOutlinedIcon />
              </a>
            </li>
          )}
          <CasesMenu />
          <li>
            <button className="btn btn-link" onClick={(): void => logOut()}>
              Logout
            </button>
          </li>
          <li className="divider"></li>
          <UserMenu />
        </ul>
      </div>
    </div>
  );
};
