import React from "react";
import moment from "moment";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";

export default function Logs() {
  return (
    <CasesDetailsConsumer>
      {(context) => (
        <>
          <div className="content-container p-3">
            <table className="table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>User</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {context.state.logs.map((log: any, index: number) => {
                  const date = moment(log.logTime).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  );
                  return (
                    <tr key={index}>
                      <td>{log.logMessage}</td>
                      <td>{log.logBy}</td>
                      <td>{date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </CasesDetailsConsumer>
  );
}
