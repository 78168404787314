import React, { FC } from "react";

type Props = {
  close: () => void;
  disableSave?: boolean;
  save: () => void;
  saveLabel: string;
};

const ModalButtons: FC<Props> = ({ close, disableSave, save, saveLabel }) => {
  return (
    <>
      <button className="btn btn-secondary" onClick={close}>
        Close
      </button>
      <button className="btn btn-primary" onClick={(): void => save()} disabled={disableSave}>
        {saveLabel}
      </button>
    </>
  );
};
export default ModalButtons;
