import React from "react";
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";
import Overview from "../tabs/overview";
import Email from "../tabs/email/email";
import Notes from "../tabs/notes";
import Documents from "../tabs/documents";
import Logs from "../tabs/logs";
import Actions from "./actions";

import TimeRemaining from "./timeRemaining";
import { CasesDetailsConsumer } from "../../../../context/casesDetailsContextProvider";
import { CasesDetailsContext } from "../../../../context/casesDetails/types";
import { EmailRulesModal } from "../../../shared/actionModals/emailRulesModal";
import { SendParams } from "../../../../models";
import ShowDocument from "../tabs/showDocument";

export default function Layout(props: any) {
  const { path, url } = useRouteMatch();

  return (
    <CasesDetailsConsumer>
      {(context: CasesDetailsContext) => {
        const currentCase = context.state.case;
        if (!currentCase) return null;
        const potentialSpam =
          currentCase !== null
            ? currentCase.spamStatus === "PotentialSpam"
            : false;
        const confirmedSpam =
          currentCase !== null ? currentCase.spamStatus === "Spam" : false;        

        return (
          <div className="content">
            {potentialSpam ? (
              <div className="row">
                <div className="col pr-0">
                  <div className="alert alert-warning alert-sm" role="alert">
                    <strong>Warning: </strong>This case has been marked as
                    potential spam.
                    <button
                      className="warning-link ml-3"
                      onClick={() => context.actions.confirmAsSpam()}
                    >
                      Confirm Spam
                    </button>
                    <button
                      className="warning-link ml-3"
                      onClick={() => context.actions.emailRulesModalOpen(true)}
                    >
                      Add to Email Rules
                    </button>
                    <button
                      className="warning-link ml-3"
                      onClick={() => context.actions.caseSetNotSpam(false)}
                    >
                      Not Spam
                    </button>
                    <button
                      className="warning-link ml-3"
                      onClick={() => context.actions.caseSetNotSpam(true)}
                    >
                      Not Spam (Send acknowledgement)
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {confirmedSpam ? (
              <div className="row">
                <div className="col pr-0">
                  <div className="alert alert-sm" role="alert">
                    Confirmed as spam.
                    <button
                      className="warning-link ml-3"
                      onClick={() => context.actions.undoConfirmAsSpam()}
                    >
                      Undo
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <EmailRulesModal
              show={context.state.isEmailRulesOpen}
              handleClose={():void=>context.actions.emailRulesModalOpen(false)}
              handleSave={context.actions.emailRulesModalSave}
              initialData={{
                acds: context.state.acds,
                caseReference: currentCase.caseReference,
                email: context.state.case?.investorEmail||"",
              }}
            />

            {context.state.errorMessage ? (
              <div className="row">
                <div className="col pr-0">
                  <div className="alert alert-danger alert-sm" role="alert">
                    <strong>Error: </strong>
                    {context.state.errorMessage}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-lg-10 col-md-9">
                <ul className="tabs">
                  <li>
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to={`${url}`}
                    >
                      Overview
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to={`${url}/email`}>
                      Email
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to={`${url}/notes`}>
                      Notes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to={`${url}/documents`}>
                      Documents
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to={`${url}/logs`}>
                      Logs
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 remaining">
                <TimeRemaining state={props.state}></TimeRemaining>
              </div>
            </div>

            <div className="row fixed-height">
              <div className="col-lg-10 col-md-9">
                <Switch>
                  <Route exact path={path}>
                    <Overview />
                  </Route>
                  <Route path={`${path}/email`}>
                    <Email />
                  </Route>
                  <Route path={`${path}/notes`}>
                    <Notes />
                  </Route>
                  <Route path={`${path}/documents`}>
                    <Documents />
                  </Route>
                  <Route path={`${path}/document/:attachmentType/:attachmentId`}>
                    <ShowDocument />
                  </Route>
                  <Route path={`${path}/logs`}>
                    <Logs />
                  </Route>
                </Switch>
              </div>
              <div className="col-lg-2 col-md-3 p-0">
                <div className="content-container">
                  <Actions 
                    acds={context.state.acds}
                    caseData={currentCase}
                    caseStatus={context.state.caseStatus}
                    send={(sendParams: SendParams):void => {
                      context.state.hubConnection.send(sendParams.type, sendParams.payload);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </CasesDetailsConsumer>
  );
}
