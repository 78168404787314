import React, { FC, useEffect, useReducer, useState } from "react";
import { Redirect } from "react-router-dom";
import { Group, PutTeamAccess, ResponseData, Team } from "../../../../../models";
import { getGroups } from "../../../../../services/get.data.service";
import { createTeam, updateTeam } from "../../../../../services/post.data.service";
import { putTeamAccesses } from "../../../../../services/put.data.service";
import MultiInput from "../../shared/fields/multiInput";
import TextInput from "../../shared/fields/textInput";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";
import ClassificationPicker from "./classificationPicker";
import teamSettingsReducer, {
  ClassificationAssignment,
  GroupClassificationAssignments,
  assignmentsAction,
} from "./assignmentsReducer";
import { getData } from "./getData";

type Props = {
  existingTeam?: Team;
  isDuplicate: (fieldToCheck: keyof Team, value: string) => boolean;
};

const TeamForm: FC<Props> = ({ isDuplicate, existingTeam }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [teamName, setTeamName] = useState<string>("");

  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [groupIdsSelected, setGroupIdsSelected] = useState<number[]>([]);

  const [groupClassificationData, groupClassificationDispatch] = useReducer(teamSettingsReducer, {});
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    getData(groupClassificationDispatch, setGroupIdsSelected, existingTeam);
    getGroups().then((response) => {
      setAllGroups(response.data);
    });
  }, []);

  useEffect(() => {
    if (existingTeam) {
      setTeamName(existingTeam.name);
    }
  }, [existingTeam]);

  const saveTeamAccesses = (savedTeamId: number): void => {
    const teamAccesses: PutTeamAccess[] = [];

    Object.values(groupClassificationData).forEach((group: GroupClassificationAssignments) => {
      Object.values(group.assignments).forEach((assignment: ClassificationAssignment) => {
        let teamId: number | undefined;
        if (assignment.team) {
          teamId = assignment.team.teamId === undefined ? savedTeamId : assignment.team.teamId;
        }
        teamAccesses.push({
          classificationId: assignment.classification.classificationId,
          groupId: group.groupId,
          teamId,
        });
      });
    });

    putTeamAccesses(teamAccesses).then(() => {
      setRedirect(true);
    });
  };

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (teamName.length === 0) {
      errorMessages.push("Team Name is required");
    } else {
      if (isDuplicate("name", teamName)) {
        errorMessages.push("Team Name has already been used");
      }
    }
    const noTeamAccessPicked = Object.values(groupClassificationData).find((group: GroupClassificationAssignments) => {
      return Object.values(group.assignments).find((assignment: ClassificationAssignment)=> {
        return assignment.team && assignment.team.teamId === existingTeam?.teamId;
      });
    }) === undefined;
    if (noTeamAccessPicked) {
      errorMessages.push("Please pick at least one group and one classification");
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existingTeam !== undefined) {
        updateTeam({
          teamId: existingTeam.teamId,
          name: teamName.trim(),
        }).then(() => {
          saveTeamAccesses(existingTeam.teamId);
        });
      } else {
        createTeam({
          name: teamName.trim(),
        }).then((teamResponse: ResponseData<Team>) => {
          saveTeamAccesses(Number(teamResponse.data.teamId));
        });
      }
    }
  };

  if (redirect) return <Redirect to="/settings/teams/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingTeam ? "Edit Team" : "Create Team"}>
        <div className="float-right p-1">
          <button className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
        <div className="float-right p-1">
          <button
            className="btn btn-secondary float-right"
            onClick={(): void => {
              setTeamName(existingTeam?.name || "");
              getData(groupClassificationDispatch, setGroupIdsSelected, existingTeam);
            }}
          >
            Reset Form
          </button>
        </div>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingTeam?.name} />
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput label="Team Name" name="team-name" onChange={setTeamName} value={teamName} />
            <MultiInput
              label="Groups"
              name="groups"
              onChange={(ids: number[]): void => {
                const idsRemoved = groupIdsSelected.filter((id: number) => !ids.includes(id));
                if (idsRemoved.length) {
                  groupClassificationDispatch({
                    type: assignmentsAction.UNASSIGN_GROUPS,
                    data: idsRemoved,
                  });
                }
                setGroupIdsSelected(ids);
              }}
              ids={groupIdsSelected}
              options={allGroups.map((group) => {
                return {
                  id: group.groupId,
                  label: group.businessName,
                };
              })}
            />
            {allGroups.length && (
              <ClassificationPicker
                groupClassifications={groupClassificationData}
                groupClassificationDispatch={groupClassificationDispatch}
                groupsSelected={groupIdsSelected.map((id) => {
                  return allGroups.find((group) => group.groupId === id) as Group;
                })}
                teamId={existingTeam?.teamId}
                teamName={teamName}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TeamForm;
