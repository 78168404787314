import React from "react";
import { CasesDetailsConsumer } from "../../../../../context/casesDetailsContextProvider";

export default function MessageList() {
  return (
    <CasesDetailsConsumer>
      {(context) => {
        return (
          <>
            {context.state.emailTab.map((email: any, index: number) => {
              let imageLetter = email.fromAddress.charAt(0);
              if (email.fromName) {
                const nameArray = email.fromName.split(" ");
                imageLetter =
                  nameArray[0].charAt(0) +
                  nameArray[nameArray.length - 1].charAt(0);
              }

              let direction = (
                <>
                  Received&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                </>
              );

              if (email.emailDirection === "Sent") {
                if (email.sending) {
                  direction = <>Sending...</>;
                } else if (email.sentSuccessfully) {
                  direction = (
                    <>
                      <i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Sent
                    </>
                  );
                } else {
                  direction = <strong>Failed to Send!</strong>
                }
              }

              if (email.sending) {
                direction = <>Sending...</>;
              }

              return (
                <button
                  onClick={() => context.actions.selectEmail(index)}
                  className={`email-message ${
                    context.state.selectedEmail === email ? "active" : ""
                  }`}
                  key={index}
                >
                  <div className="message-image">
                    <span className="image">{imageLetter}</span>
                  </div>
                  <div className="message-content">
                    <div className="name">
                      {email.importedSuccessfully === false && (
                        <>
                          <i className="fas fa-exclamation-triangle warning"></i>
                          &nbsp;
                        </>
                      )}
                      {email.removedFromInbox === true && (
                        <>
                          <i className="fas fa-exclamation-triangle emergency-warning"></i>
                          &nbsp;
                        </>
                      )}
                      
                      {email.sentSuccessfully === false ? (
                        <>
                          <i className="fas fa-exclamation-triangle emergency-warning"></i>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                      {email.fromName ? email.fromName : email.fromAddress}
                    </div>
                    <div className="subject">{email.subject}</div>
                    <div className="status">{direction}</div>
                  </div>
                </button>
              );
            })}
          </>
        );
      }}
    </CasesDetailsConsumer>
  );
}
