import React, { useEffect, useState } from "react";
import { Chart, Tooltip } from "chart.js";

Chart.register(Tooltip);

interface Props {
  users: string[];
  cases: number[];
}

const DashboardAllocationChart = ({ users, cases }: Props): JSX.Element => {
  const [chartHeight, setChartHeight] = useState<number>(0);
  
  useEffect(() => {
    const filteredUsers = users.filter((user: string, index: number)=>{
      return cases[index] > 0;
    });
    const filteredCases = cases.filter((theCase: number)=>{
      return theCase > 0;
    });
    const allocationChart = new Chart("allocation-chart", {
      type: "bar",
      data: {
        labels: filteredUsers,
        datasets: [
          {
            barPercentage: 1,
            maxBarThickness: 10,
            backgroundColor: "#f7a66a",
            data: filteredCases,
          },
        ],
      },
      options: {
        font: { family: "Source Sans Pro", size: 11 },
        indexAxis: "y",
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            ticks: {
              callback: (tick):string|number => {
                if (tick.toString().indexOf(".") > 0) {
                  return ""; // Don't show 0.1, 0.2 of a case
                } else {
                  return tick;
                }
              },
            },
          },
        },
      },
    });

    setChartHeight(filteredUsers.length * 30);
    return (): void => {
      if (allocationChart) {
        allocationChart.destroy();
      }
    };
  });

  return (
    <div className="chart-flex">
      <div className="chart-section">
        <div className="chart-relative" style={{ height: `${chartHeight}px`, minHeight: "180px" }}>
          <canvas id="allocation-chart"></canvas>
        </div>
      </div>
    </div>
  );
};

export default DashboardAllocationChart;
