import { TypeAheadValues } from "../../pages/teamq/newCaseForm";

const isInternalMail = (toAddresses: Array<string|TypeAheadValues>, ccAddresses: Array<string|TypeAheadValues>): boolean => {    
  
  if (toAddresses?.find((address: TypeAheadValues|string)=>{
    const addressString = typeof address !== "string" ? address.label : address;
    return !addressString.includes("@fnz.");
  })) {
    return false;
  }
  
  if (!ccAddresses) {
   return true;
  }
  
  if(ccAddresses.find((address: TypeAheadValues|string)=>{
    const addressString = typeof address !== "string" ? address.label : address;
    return !addressString.includes("@fnz.");
  })) {
    return false;
  }

  return true;
}
export default isInternalMail;