import { Reducer } from "react";

export enum casesInFlightAction {
  REMOVE_ONE,
  REPLACE,
}

export type removeOneAction = {
  type: casesInFlightAction.REMOVE_ONE;
  caseReference: string;
};

export type replaceAction = {
  type: casesInFlightAction.REPLACE;
  caseReferences: string[];
};

const casesInFlightReducer: Reducer<string[], removeOneAction | replaceAction> = (state, action) => {

  switch (action.type) {

    case casesInFlightAction.REMOVE_ONE:{
      return state.filter((caseRef: string)=>{
        return caseRef !== action.caseReference;
      });
  }
    case casesInFlightAction.REPLACE:
      return action.caseReferences;
  }
}; 
export default casesInFlightReducer;