export enum CaseSpam {
  none = "None",
  potentialSpam = "PotentialSpam",
  notSpam = "NotSpam",
  spam = "Spam",
}

export function isNotSpam(state: CaseSpam): boolean {
  return CaseSpam.none === state || CaseSpam.notSpam === state;
}

export function isSpam(state: CaseSpam): boolean {
  return CaseSpam.potentialSpam === state || CaseSpam.spam === state;
}
