import React, { FC, createContext, useState } from "react";
import { PortalContextValue } from "./portal/types";

export interface Props {
  children: React.ReactNode;
}

export const PortalContext = createContext<PortalContextValue>(
  {} as PortalContextValue
);

export const PortalContextProvider: FC<Props> = ({ children }: Props) => {
  const [isSidenavOpen, updateSidenavOpen] = useState<boolean>(true);

  const portalContextValue: PortalContextValue = {
    isSidenavOpen,
    updateSidenavOpen,
  };
  return (
    <PortalContext.Provider value={portalContextValue}>
      {children}
    </PortalContext.Provider>
  );
};
