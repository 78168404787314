import React, { FC } from "react";
import { useUserContext } from "../../../context/userContextProvider";
import { User } from "../../../models";
import CommonModal from "../components/commonModal";
import useModalState from "./hooks/useModalState";
import { ConfirmInputGroup } from "./shared/confirmInputGroup";
import ModalButtons from "./shared/modalButtons";

const getError = (id: string, message: string): string => {
  return !id || !message ? "Please select a user and enter a note to reassign case" : "";
};

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSave: (user: number, message: string) => void;
}

export const AssignUserModal: FC<Props> = ({ show = false, handleClose, handleSave }) => {
  const { close, errorComponent, id, message, save, setId, setMessage } = useModalState(
    handleClose,
    handleSave,
    getError
  );

  const userContext = useUserContext();

  return (
    <CommonModal
      show={show}
      title="Assign to User"
      onHide={close}
      buttons={<ModalButtons close={close} save={save} saveLabel="Assign" />}
    >
      {errorComponent}
      {userContext.state.users.length > 0 ? (
        <ConfirmInputGroup
          choices={userContext.state.users.map((user: User) => {
            return {
              id: user.id,
              label: `${user.firstName} ${user.lastName} <${user.emailAddress}>`,
            };
          })}
          handleIdChange={setId}
          handleNoteChange={setMessage}
          id={id}
          note={message}
          title="Assign to"
          placeholder="Select User"
        />
      ) : (
        <>There are no users found that match the team that this case is assigned to</>
      )}
    </CommonModal>
  );
};
