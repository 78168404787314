import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Classification } from "../../../../../models";
import HubService from "../../../../../services/hub.service";
import TextInput from "../../shared/fields/textInput";
import sendSetting from "../../shared/helpers/sendSetting";
import SettingsFormInfo from "../../shared/settingsFormInfo";
import SettingsTitle from "../../shared/settingsTitle";

type Props = {
  isDuplicate: (fieldToCheck: keyof Classification, value: string)=> boolean;
  existingClassification?: Classification;
  hub: HubService;
};

const ClassificationForm: FC<Props> = ({ isDuplicate, existingClassification, hub }) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (existingClassification) {
      setName(existingClassification.name);
    }
  }, [existingClassification]);

  const validate = (): string[] => {
    const errorMessages: string[] = [];
    if (name.length === 0) {
      errorMessages.push("Name is required");
    } else {
      if (isDuplicate("name", name)) {
        errorMessages.push("Name has already been used");
      }
    }
    return errorMessages;
  };

  const save = (): void => {
    const errorMessages = validate();
    setErrors(errorMessages);
    if (!errorMessages.length) {
      if (existingClassification) {
        sendSetting(hub, {
            type: "UpsertAClassification",
            payload: {
              classificationId: existingClassification.classificationId,
              name: name.trim(),
            },
          }, setRedirect);
      } else {
        sendSetting(hub, {
            type: "UpsertAClassification",
            payload: {
              name: name.trim(),
            },
          }, setRedirect);
      }
    }
  };

  if (redirect) return <Redirect to="/settings/classifications/list" />;

  return (
    <>
      <SettingsTitle errors={errors} title={existingClassification ? "Edit Classification" : "Create Classification"}>
        <button className="btn btn-primary float-right" onClick={save}>
          Save
        </button>
      </SettingsTitle>
      <div className="row">
        <SettingsFormInfo existingTitle={existingClassification?.name} >
        </SettingsFormInfo>
        <div className="col-sm-7">
          <div className="content-container p-3">
            <TextInput 
              label="Name" 
              name="classification-name" 
              onChange={setName} 
              value={name} 
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ClassificationForm;
